import firebase from 'firebase/compat/app';
// src/utils/timestamp.js
import 'firebase/compat/firestore';
import { firestore } from './firebase';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
// import { getAuth } from "firebase/auth";

// const auth = getAuth();

// const user = auth.currentUser;
// const userid = user.uid;
const db = firebase.firestore();


const saveLastClosedTime = async (userId, length, lastCount, numofmp3, subIndex) => {
  const now = Date.now();
  const userDocRef = doc(firestore, 'users', userId);

  try {
    // Fetch the current document
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const data = userDoc.data();
      const subliminals = data.subliminals;
      // console.log('numofmp3: ', numofmp3)
      // Check if subIndex is valid
      if (subIndex !== null && subliminals && subliminals.length > subIndex) {
        // Update the specific element in the array
        const updatedSubliminal = {
          ...subliminals[subIndex],
          lastClosed: now,
          mp3length: length,
          livingCount: numofmp3,
          lastCount: lastCount
        };

        // Create a new array with the updated element
        const updatedSubliminals = [...subliminals];
        updatedSubliminals[subIndex] = updatedSubliminal;

        // Update the document with the new array
        await updateDoc(userDocRef, {
          subliminals: updatedSubliminals
        });

        console.log('Subliminal updated successfully');
      } else {
        console.error('Invalid subIndex');
      }
    } else {
      console.error('User document does not exist');
    }
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};

const getLastClosedTime = async (userId, subIndex) => {
  const userDocRef = doc(firestore, 'users', userId);
  const docSnap = await getDoc(userDocRef);

  if (docSnap.exists()) {
    const userData = docSnap.data();
    const subliminals = userData.subliminals;

    if (subIndex !== null && subliminals && subliminals.length > subIndex) {
      const subData = subliminals[subIndex];

      var { mp3length, lastClosed, lastCount, livingSub, livingCount } = subData;
      const now = Date.now();
      const timepassed = now - lastClosed;
      const secondsPassed = timepassed / 1000;

      // console.log('lastClosed: ', lastClosed);
      // console.log('mp3Length: ', mp3length);
      // console.log('secondsPassed: ', secondsPassed);
      // console.log('livingSub: ', livingSub);
      // console.log('livingCount: ', livingCount);
      // console.log('lastCount: ', lastCount);

      if(lastCount == undefined && mp3length == undefined){
        mp3length = 0 
        lastClosed = 0  
        lastCount = 0  
        livingSub = false 
        livingCount = 0
      }

      if(livingCount == Infinity){
        // todo: livingCount==Infinity
        livingCount = 0
      }

      var numofmp3
      var tostart

      if(livingSub){
        numofmp3 = Math.floor(secondsPassed / mp3length);
        tostart = secondsPassed % mp3length;
        numofmp3 = numofmp3 + livingCount
      }else{
        numofmp3 = livingCount
        tostart = 0
      }
      

      // console.log('numofmp3: ', numofmp3);
      // console.log('toStart: ', tostart);

      return [numofmp3, tostart, mp3length, lastCount, livingSub];
    } else {
      // console.error('Invalid subIndex or subliminals array does not exist.');
      return null;
    }
  } else {
    // console.error('User document does not exist.');
    return null;
  }
};

// const getLastClosedTime = async (userId, subIndex) => {
//     const userDocRef = doc(firestore, 'users', userId);
//     const docSnap = await getDoc(userDocRef);
//   if (docSnap.exists()) {
//     console.log('lastclosed: ', docSnap.data().lastClosed)
//     console.log('mp3Length: ', docSnap.data().mp3length)
//     const length = docSnap.data().mp3length
//     const now = Date.now()
//     const timepassed = now - docSnap.data().lastClosed;
//     const secondsPassed = timepassed / 1000;
//     console.log('secondsPassed: ', secondsPassed)
//     // const mp3length = 60
//     const numofmp3 = Math.floor(secondsPassed / length);
//     console.log("numofmp3: ", numofmp3)
//     const tostart = secondsPassed % length;
//     console.log("toSTart: ", tostart)
//     return [numofmp3, tostart, length];
//   } else {
//     return null;
//   }
// };

export { saveLastClosedTime, getLastClosedTime };