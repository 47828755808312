// import logo from './logo.svg';
import {Form,  Modal, FloatingLabel, Button, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col} from 'react-bootstrap'
import React, {useState, useEffect, useRef} from "react";
// import './App.css';
import { Link, useNavigate } from 'react-router-dom';
// import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { collection, addDoc, query, where, getDocs, getFirestore, count } from 'firebase/firestore';
import { firestore } from './firebase';
import 'firebase/compat/firestore';
import axios from 'axios';
import OpenAI from "openai";
import Countdown from 'react-countdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { doc, updateDoc, arrayUnion, setDoc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import mixpanel from 'mixpanel-browser';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import Accordion from 'react-bootstrap/Accordion';
// import UploadPhoto from './UploadPhoto';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from './firebase';
import Figure from 'react-bootstrap/Figure';
import { Analytics } from "@vercel/analytics/react"
// import { Lame } from 'lamejs';
// import MPEGMode from 'lamejs/src/js/MPEGMode';
// import Lame from 'lamejs/src/js/Lame';
import Lame from 'lamejs';
import Image from 'react-bootstrap/Image';
import { getLastClosedTime, saveLastClosedTime } from './timestamp';
import { debounce } from 'lodash';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Badge from 'react-bootstrap/Badge';

// var lamejs = require("lamejs");
const auth = getAuth();


let data_low;
let data_up = null
let giant;
let energy;
let ene_comp;
let ene_sup;
let fullenergy
let fullName
let freqTop
let subUrl
let audioUrl
let source
// let biquadFilter
// let gainNode
let new_data
let audioContext
let bColor
let audioBlobUrl
let userid
let subIndexG
// let mp3Blob

// Define a function to return the JSX for the formatted string
const getInfo2 = (energy, giant, frequency, ene_sup, ene_comp) => (

  <>  
    If 1 x {giant} is the {ene_sup}, {giant} can be.
  </>
);

const getInfo1 = (energy, giant) => (
 
  <>
     Click <img src="./fire6.png" width="25" />
 

  </>
);

const energyList = ['Play Magic', 'Make Magic',  ];
const tags = ['health', 'skill', 'beauty', 'mental health', 'part of the body', 'success', 'acceptance', 'relationship', 'job', 'money', 'spirituality', 'skill'];

const Home = ({handleConversationTransfer, userPhoto, userName, showMysub, handleCloseMySub, handleShowMySub}) => {
  const [data,setData]=useState('Happy');
  const [cards, setCards] = useState([]);
  const db = firebase.firestore();
  const [showIfEmpty, setShowIfEmpty] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [fullname, setFullname] = useState('null');
  // const [fullenergy, setFullenergy] = useState('null');
  const [pastins, setPastins] = useState('null');
  const [comp, setComp] = useState('null');
  const [sup, setSup] = useState('null');
  const [audioUrls, setAudioUrls] = useState([]);
  const [audio1Urls, setAudio1Urls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [replayCount, setReplayCount] = useState(0);
  const [selectedAudioUrl, setSelectedAudioUrl] = useState(null); // State to track the selected audio URL
  const audioRef = useRef(null);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [myDesire, setDesire] = useState(null);
  const [countdown, setCountdown] = useState(60); // Countdown state
  const [frequency, setFrequency] = useState(0); // Frequency state
  const [fred, setFred] = useState(0); // Frequency state
  const [resistance, setResistance] = useState(0); // Resistance state
  const [selectedCard, setSelectedCard] = useState(null); // State to store selected card details
  const [isIncreasing, setIsIncreasing] = useState(false);
  const [showNewContent, setShowNewContent] = useState(false);
  const [isHuman, setIsHuman] = useState(true);
  const [article, setArticle] = useState('');
  const [showTraits, setShowTraits] = useState(true);
  const [showGenerated, setShowGenerated] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [inspirationText, setInspirationText] = useState("");
  const [filteredHints, setFilteredHints] = useState([]);
  const [hints, setHints] = useState([]);
  const [showSignInButton, setShowSignInButton] = useState(false); // Add state for sign-in modal
  const [user, setUser] = useState(auth.currentUser);
  const [sheyRaw, setSheyRaw] = useState("Raw Energy to Human Energy");
  const [firstName, setFirstName] = useState('');
  const [countdownTime, setCountdownTime] = useState(0); // 3:33 minutes in milliseconds
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [dots, setDots] = useState(3);
  const [currentEnergy, setCurrentEnergy] = useState(energyList[0]);
  const [index, setIndex] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [corn, setCorn] = useState('Humans');
  const [selectedOption, setSelectedOption] = useState("");
  const [otherOption, setOtherOption] = useState("");
  const [isRestart, setIsRestart] = useState(false);
  const [selectedSmallCard, setSelectedSmallCard] = useState('Water');
  const [selectedSmallCard6, setSelectedSmallCard6] = useState('Masculine');
  const [selectedSmallCard9, setSelectedSmallCard9] = useState('Baby');
  const [settings, setSettings] = useState(false);
  const [explanation, setExplanation] = useState('');
  const [selectedPower, setPowerOption] = useState('');
  // const [freqTop, setFreqTop] = useState('');
  const [range_explanation, setRangeExplanation] = useState('');
  const [subfreq, setSubfreq] = useState(555); // Default to 17,500 Hz
  const [selectedBackground, setSelectedBackground] = useState(0);
  const [isButtonPressed, setIsButtonPressed] = useState(false);
  const [isButtonReleased, setIsButtonReleased] = useState(false);
  const [audibility, setAudibility] = useState('audible');
  const [numofmp3, setNumofmp3] = useState(0);
  const [tostart, setToStart] = useState(0);
  const [notifications, setNotifications] = useState(0);
  const [unplayed, setUnplayed] = useState([]);


  const [lllastCount, setLllastCount] = useState(0);
  const [lllivingSub, setLlivingSub] = useState(false);

  const [showupload, setShowUpload] = useState(false);
  const [energyval, setEnergyVal] = useState('');
  const [namevalue, setNameValue] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [PhotoUploadUrl, setPhotoUploadUrl] = useState('');
  const [subliminals, setSubliminals] = useState([]);
  const [currentSubIndex, setCurrentSubIndex] = useState(0);
  const [selectedSubliminal, setSelectedSubliminal] = useState(null);
  const [error, setError] = useState('');
  const [error1, setError1] = useState('');
  const [error2, setError2] = useState('');
  const [error3, setError3] = useState('');
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0); // State to store audio duration
  const [loopCount, setLoopCount] = useState(0);
  const [timer, setTimer] = useState(0);
  var [counter, setCounter] = useState(0);
  const [subIndex, setSubIndex] = useState(null);
  const [visibleAffirmations, setVisibleAffirmations] = useState(5);
  const [selectedItems, setSelectedItems] = useState([]);

  const [isRunning, setIsRunning] = useState(false);
  const [affirmations, setAffirmations] = useState([]);
  const [testimonies, setTestimonies] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [subTags, setSubTags] = useState([]);
  const [triggerTags, setTriggerTags] = useState(false);
  const [population, setPopulation] = useState(0);
  const [subPopulation, setSubPopulation] = useState(0);
  const [triggerPopulation, setTriggerPopulation] = useState(false);
  const [popExplanation, setPopExplanation] = useState('');
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const handleToggle = (activeKey) => {
    setIsAccordionOpen(activeKey === "0");
    mixpanel.track('channeling wheel?', {
      popExplain: popExplanation,
      pop: population
    })
  };
  
  const [speed, setSpeed] = useState(population); // Default rotation speed
  const [people, setPeople] = useState(0); // Default rotation speed

  const [on, setOn] = useState([]);
  const [by, setBy] = useState([]);

  const [showsave, setShowSave] = useState(false);
  const intervalRef = useRef(null);
  // const timerLimit = 10; // Set the limit in sec

  const audioContextRef = useRef(null);
  const gainNodeRef = useRef(null);
  const biquadFilterRef = useRef(null);
  const audioRefs = useRef([]);

  // const audioBufferSourceRef = useRef(null);

  const timerRef = useRef(null);
  var [id, setID]=useState([]);
  const [file, setFile] = useState(null);
  
  const stripe = useStripe();
  const elements = useElements();
  const [currentResultIndex, setCurrentResultIndex] = useState(0);

  

  // const me = auth.currentUser;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

// console.log("showMysub: ", showMysub)
const [showPay, setShowPay] = useState(false);

const handleClosePay = () => setShowPay(false);
const handleShowPay = () => setShowPay(true);

const handleSelectItem = (affirmation) => {
  if (selectedItems.includes(affirmation)) {
    // Remove the item if it's already selected
    setSelectedItems(selectedItems.filter(item => item !== affirmation));
  } else {
    // Add the item to the selection
    setSelectedItems([...selectedItems, affirmation]);
  }
};

const handleCheckout = async () => {
  setIsLoading(true);

  const response = await fetch('/create-checkout-session', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  const session = await response.json();

  // Redirect to Stripe Checkout
  const result = await stripe.redirectToCheckout({ sessionId: session.id });

  if (result.error) {
    setError(result.error.message);
  }

  setIsLoading(false);
};

  const handleSubCardClick = (subliminal, index) => {
    mixpanel.track('clickMySubCard', {
      text: subliminal.text
    })
    setSubTags(subliminal.tags)
    // console.log('subIndex:', index)
    // console.log('subTags:', subliminal.tags)
    // console.log('subTags:', subTags)
    // then set the tags in subTag
    // getTestimony()
    setSelectedSubliminal(subliminal);
    setSearchText(subliminal.text);
    setSubIndex(index)
    subIndexG = index
    // console.log('subIndex2:', subIndex)
    setShowUpload(true);
    // setSpeed(numofmp3)
    // setSpeed(subliminal.livingCount)
    getPopulation(subliminal.text)
    callOpenAIForTags(subliminal.text)
    handleCloseMySub();
  };

  const handleCloseUpload = () => {
    setSpeed(0)
    setShowUpload(false);
    setSelectedSubliminal(null);
    setShowSave(false)
    setShowFullText(false);
    // setSearchText('');
    // saveLastClosedTime()
    handleShowMySub()
    setError('')
    setError1('')
    setError2('')
    setError3('')
    setSubTags([])
    setTestimonies([])
    setIsLoading(false)
    setSelectedTags([]);
    setPopulation(0)
    setPopExplanation('')
    // setNumofmp3(0)
    // setLllastCount(0)
    // setCounter(0)
    setNewNotes([])
    setNotifications(0)
    setIsAccordionOpen(false)
  };

  const handleShowUpload2 = (affirmations) => {

    // getTestimony()
    // handleSelectItem(affirmations)
    if (Array.isArray(affirmations)) {
      // Join all the elements in the array into a single string
      affirmations = affirmations.join(' ');
      // console.log(combinedText);
    }

    setSearchText(affirmations)
    setNumofmp3(0);
    setToStart(0);
    setDuration(0)
    setLllastCount(0)
    setCounter(0)
    setSubIndex(null)
    subIndexG = null
    setLlivingSub(false)
    setShowSave(true)
    callOpenAIForTags(affirmations)
    getPopulation(affirmations)
    if(error1 !== ''){
      return;
    }
    mixpanel.track('cardClicked', {
      // user: userEmail,
      // energy: ene
      text:affirmations
    })
    // setNumofmp3(0)
    // setLllastCount(0)
    // setCounter(0)
    setShowUpload(true);
  }

  const handleShowUpload = () => {
    setNumofmp3(0);
    setToStart(0);
    setDuration(0)
    setLllastCount(0)
    setCounter(0)
    setSubIndex(null)
    subIndexG = null
    setLlivingSub(false)
    setShowSave(true)
    if(error1 !== ''){
      return;
    }
    mixpanel.track('play1', {
      // user: userEmail,
      // energy: ene
      text:searchText
    })
    callOpenAIForTags(searchText)
    // setNumofmp3(0)
    // setLllastCount(0)
    // setCounter(0)
    setShowUpload(true);
  }

  const handleMouseDown = (event) => {
    if (event.button === 0) { // Only respond to left-click
      setIsButtonPressed(true);
      // setIsButtonReleased(false);
      // console.log('pressed')
    }
  };

  const handleMouseUp = () => {
    setIsButtonPressed(false);
    // setIsButtonReleased(true);
    // console.log('released')
  };

  const handleMouseLeave = () => {
    setIsButtonPressed(false);
  };

  const handleEnergyChange = (e) => {
    setEnergyVal(e.target.value);
  };

  const handleNameChange = (e) => {
    setNameValue(e.target.value);
  };

  const handlePhotoUploadComplete = (url) => {
    setPhotoUrl(url);
  };
  const handleCloseSettings = () => {
    setSettings(false)
    // setShowModal(true)
    setPowerOption('')
}

const handleStart = () => {
  mixpanel.track('beginBooster', {
    // user: userEmail,
    energy: energy,
    sub: explanation,
    numberofRep: rangeValue
  })

  if(selectedPower == '') {
    alert('Please choose a subliminal')
    return;
  }
  setSettings(false)
  setShowModal(true)
  // setPowerOption('')
  
}
  const handleShowSettings = () => setSettings(true);

  const info2 = getInfo2(energy, giant, frequency, ene_sup, ene_comp)
  const info1 = getInfo1(energy, giant, frequency)
  const handleCloseSignInButton = () => setShowSignInButton(false); // Function to close sign-in modal

  const navigate = useNavigate();
  const [rangeValue, setRangeValue] = useState(0);

  const handleRangeChange = (e) => {
    setRangeValue(parseInt(e.target.value));
    // console.log(rangeValue)
    // console.log(ranges[rangeValue])
    freqTop = ranges[rangeValue]
  };

  const handlePowerChange = (e) => {
    const value = e.target.value;
    setPowerOption(value);
    setExplanation(explanations[value] || '');
  };


 

  const handleInputChange = (event) => {
    setSpeed(event.target.value);
    setPeople(event.target.value)
  };

 
  useEffect(() => {
    const wheel = document.getElementById("wheel");

    if (wheel) { // Ensure the element exists before trying to access its style
      const animationDuration = 1000000000 / population; // Speed to milliseconds 1000
      wheel.style.animationDuration = `${animationDuration}ms`;
    }
  }, [population]);


  const explanations = {
    Remember_how: "Remember how you are " + comp + ' than ' + article  + fullName,
    Remember_you: "Remember you are " + comp + ' than ' + article  + fullName,
    Imagine: "Imagine that you are " + comp + ' than ' + article  + fullName,
    Acknowledge: "Acknowledge that you are  " + comp + ' than ' + article  + fullName,
    Because: "Because you are " + comp + ' than ' + article  + fullName,
    Sooner: "Sooner or Later you will be " + comp + ' than ' + article  + fullName,
    I_find: "I find you to be " + comp + ' than ' + article  + fullName,
    Be_Like_If: "What Would It Be Like If you are " + comp + ' than ' + article  + fullName,
    Realize: "Realize that you are " + comp + ' than ' + article  + fullName,
    Suppose: "Suppose you are " + comp + ' than ' + article  + fullName,
    Like_when: "What is it like When you are "+ comp + ' than ' + article  + fullName, 
    Notice: "Notice that you are " + comp + ' than ' + article  + fullName,
    I_allow: "Allow yourself to be " + comp + ' than ' + article  + fullName,
  };

  const explanationsmorethanone = {
    Remember_how: "Remember how you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    Remember_you: "Remember you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    Imagine: "Imagine that you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    Acknowledge: "Acknowledge that you are  " + frequency + " times " + comp + ' than ' + article  + fullName,
    Because: "Because you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    Sooner: "Sooner or Later you will be " + frequency + " times " + comp + ' than ' + article  + fullName,
    I_find: "I find you to be " + frequency + " times " + comp + ' than ' + article  + fullName,
    Be_Like_If: "What Would It Be Like If you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    Realize: "Realize that you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    Suppose: "Suppose you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    Like_when: "What is it like When you are "+ frequency + " times " + comp + ' than ' + article  + fullName, 
    Notice: "Notice that you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    I_allow: "Allow yourself to be " + frequency + " times " + comp + ' than ' + article  + fullName,
  };

  const ranges = [
    3,
    6,
    9,
  ];

  const handleSmallCardClick = (name) => {
    setSelectedSmallCard(name);
    // console.log(name);
  };

  const handleSmallCardClick6 = (name) => {
    setSelectedSmallCard6(name);
    console.log(name);
  };

  const handleSmallCardClick9 = (name) => {
    setSelectedSmallCard9(name);
    console.log(name);
  };

  const smallCardData = [
    { name: 'Earth', src: './earth.jpg', alt: 'earth' },
    { name: 'Water', src: './waterr.jpg', alt: 'water' },
    { name: 'Wind', src: './wind.jpg', alt: 'wind' },
    { name: 'Fire', src: './firee.jpg', alt: 'fire' },
  ];

  const smallCardData6 = [
    { name: 'Masculine', src: './male.jpg', alt: 'male' },
    { name: 'Feminine', src: './female.jpg', alt: 'female' },
    // { name: 'Wind', src: './wind.jpg', alt: 'wind' },
    // { name: 'Fire', src: './firee.jpg', alt: 'fire' },
  ];

  const smallCardData9 = [
    { name: 'Baby', src: './baby.jpg', alt: 'Baby' },
    { name: 'Youth', src: './youth.png', alt: 'Youth' },
    { name: 'Adult', src: './adult.png', alt: 'Adult' },
    { name: 'Old', src: './old.avif', alt: 'Old' },
  ];

  const colors = [
    { name: 'White', code: '#FFFFFF' },
    { name: 'Red', code: '#C60000' },
    { name: 'Yellow', code: '#FFC300' },
    { name: 'Green', code: '#2ECC71' },
    { name: 'Blue', code: '#5DADE2' },
    { name: 'Black', code: '#000000' },
  ];

  // const info1 = `
  //               There is a version of you in the parallel universe more "${energy}" than "${giant}".
  // //             `
  // const info2 = `
  //                 Imagine the ${energy} ${<b>greater than</b>} of ${giant} imposed instantly on your memory. What frequency would your brain be at?

  //               `
// console.log("userPhoto2: ", pastins)

const handleUpload = async () => {
  const mp3Blob = await convertAudioBufferToMP3(audioBlobUrl, 555)
  if (!mp3Blob) {
    alert('click make first')
    return;
  }

  console.log('File MIME type:', mp3Blob.type); // Check MIME type
  console.log('File size:', mp3Blob.size); // Check file size

  const storageRef = ref(storage, `subliminals/${Date.now()}_${userid}.mp3`);
  const uploadTask = uploadBytesResumable(storageRef, mp3Blob);

  uploadTask.on('state_changed',
    (snapshot) => {
      // Optional: You can use this to track the upload progress
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log(`Upload is ${progress}% done`);
    },
    (error) => {
      console.error('Upload failed:', error);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        subUrl = downloadURL
        console.log('File available at', downloadURL);
      //   onUploadComplete(downloadURL);  // Propagate the URL
        handleUploadSub()
      })
      
    }
  );
};

const shareToFriends = (text) => {
  mixpanel.track('shareWithfriends', {
    sub: text
  })
  const shareUrl = `https://www.playsubs.com?text=${encodeURIComponent(text)}`;
  if (navigator.share) {
    navigator.share({
      title: 'Listen to this Message',
      text: text,
      url: shareUrl
    }).then(() => {
      // console.log('Thanks for sharing!');
    }).catch(console.error);
  } else {
    // Fallback for browsers that don't support the Web Share API
    window.prompt("Copy this link to share:", shareUrl);
  }
};

const handleUploadSub = async () => {
  if(error2 !== ''){
    return;
  }
  if(error1 !== ''){
    return;
  }
  if(duration == 0){
    setError2('Please click play before saving... ')
    return;
  }
  mixpanel.track('uploadSub', {
    message: "card clicked while signed in"
  })
  const user = auth.currentUser;

  if (user) {
    userid = user.uid;
     // Ensure searchText and audioUrl are defined
    //  if (searchText === undefined || subUrl === undefined) {
    //   console.error('searchText: ', searchText);
    //   console.error('audioUrl: ', subUrl);
    //   return;
    // }
      var date = new Date().toLocaleDateString()
      var time = new Date().toLocaleTimeString()

    try {
      const userDocRef = doc(db, 'users', userid);

      // Fetch the existing document to check if it exists
      const docSnap = await getDoc(userDocRef);
// TODO: When you close
      if (docSnap.exists()) {
        console.log('here1')
        // Document exists, update it
        await updateDoc(userDocRef, {
          subliminals: arrayUnion({
            text: searchText,
            audio: '',
            date:date,
            time: time,
            mp3length: duration,
            lastClosed:0,
            lastCount:counter,
            livingCount:0,
            livingSub:lllivingSub,
            tags:selectedTags,
          })
        });
      } else {
        console.log('here2')
        // Document does not exist, create it with the initial data
        await setDoc(userDocRef, {
          subliminals: [
            {
              text: searchText,
              audio: '',
              date:date,
              time: time,
              tags:selectedTags,
              mp3length: duration,
              lastClosed:0,
              lastCount:counter,
              livingCount:0,
              livingSub:lllivingSub,
            }
          ]
        });
      }

      // console.log('Document successfully written!');
      handleCloseUpload();
    } catch (e) {
      // console.error('Error adding document: ', e);
    }
  }else{
    setShowSignInButton(true)
  }
};

const handleContextMenu = (e) => {
  e.preventDefault();
  setIsButtonPressed(false);
};

const handleLoadMore = () => {
  setCurrentSubIndex(currentSubIndex + 10);
};

// const displayedSubliminals = subliminals.slice(0, currentSubIndex + 10);

// const calculateStartTime = async () => {
//   const lastClosed = await getLastClosedTime(userid);
  // if (lastClosed) {
  //   const now = Date.now();
  //   const elapsed = (now - lastClosed) / 1000; // elapsed time in seconds
  //   const startTime = elapsed % duration;
  //   audioRef.current.currentTime = startTime;
  // }
  // audioRef.current.play();
  // setIsPlaying(true);
// };



useEffect(() => {
  const handleLastClosedTime = async () => {
    const me = auth.currentUser;
    if(me){
      var userrid = me.uid;
  
      if(showupload){
        // console.log('its open')
        setAudioUrls([]);
        setCounter(0)
        // let livingMeta = new Array();
        // console.log("subIndex: ", subIndex)
        // console.log('numofmp3:', numofmp3)
        // console.log('counter:', counter)
        // console.log('lllastCount:', lllastCount)
        const livingMeta = await getLastClosedTime(userrid, subIndexG)
        if (livingMeta) {
          const llivingSub = livingMeta[4];
          const nnumofmp3 = livingMeta[0];
          const ttostart = livingMeta[1];
          const dduration = livingMeta[2];
          const llastCount = livingMeta[3];
         

            if(llivingSub){

              setNumofmp3(nnumofmp3);
              setToStart(ttostart);
              setDuration(dduration)
              setLllastCount(llastCount)
              setLlivingSub(llivingSub)
              // console.log('numofmp3: ', numofmp3);
              // console.log('toSTart: ', tostart);

            }else{
              setNumofmp3(nnumofmp3);
              setToStart(0);
              setDuration(dduration)
              setLllastCount(llastCount)
              setLlivingSub(llivingSub)
            }

        }else {
          // console.log('No previous session data found.');
        }
      }else{
        setAudioUrls([]);
        setCounter(0)
        // console.log('its closed')
        var lastCount = counter + lllastCount
        // console.log("subIndex: ", subIndex)
        // console.log('numofmp3:', numofmp3)
        // console.log('counter:', counter)
        // console.log('lllastCount:', lllastCount)
        // TODO: you must click play to get the duration
        saveLastClosedTime(userrid, duration, lastCount, numofmp3, subIndexG)
        // setDuration(0)
        // setLllastCount(0)
        // setLlivingSub(false)
        // setCounter(0)
        // setNumofmp3(0)
        
        // lastCOunt + timer + rolling
      }
   }
  }
 
  
  handleLastClosedTime();
  // calculateStartTime();
}, [showupload]);

useEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const text = urlParams.get('text');
  if (text) {
    handleSearchTextChange({ target: { value: text } });
  }
}, []);

useEffect(() => {
  const fetchSubliminals = async () => {
    if (userName) {
      const userDocRef = doc(firestore, 'users', auth.currentUser.uid); // Adjust auth.currentUser.uid as necessary
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        setSubliminals(userData.subliminals || []);
      }
    }
  };

  fetchSubliminals();
}, [showMysub === true]);

useEffect(() => {
  if (isButtonPressed){
    bColor = '#FFF2EE'
  }else{
    bColor = 'white'
  }
  
}, [isButtonPressed]);

useEffect(() => {
  const nameParts = userName.split(' ');
  setFirstName(nameParts[0]);
}, [userName]);

useEffect(() => {
  if (ranges[rangeValue] == 3){
    // console.log(3.33)
    setCountdownTime((71000/5) * 3)
    freqTop = (71000/5) * 3

  }
  if (ranges[rangeValue] == 6){
    // console.log(6.66/2)
    setCountdownTime((71000/5) * 6)
    freqTop = (71000/5) * 6
  }
  if (ranges[rangeValue] == 9){
    // console.log(9.99/2)
    setCountdownTime((71000/5) * 9)
    freqTop = (71000/5) * 9
  }
}, [ranges[rangeValue]]);


useEffect(() => {
  if (selectedPower) {
    const explanationText = explanations[selectedPower];
    setExplanation(explanationText);
    setRangeExplanation(`${explanationText.split(comp)[0]}<b>${ranges[rangeValue]} times</b> ${comp} than ${article} ${fullName}`);
  }
}, [selectedPower, rangeValue]);


  const voices = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer']

  const handleButtonClick = () => {
    setFrequency(prevFrequency => (prevFrequency >= 9 ? 1 : prevFrequency + 3));
  };

  const handleCardClick = async (ene, card_docid, cardname, card) => {
    mixpanel.track('cardClicked', {
      card: cardname,
      energy: ene
    })

    setFrequency(0)
    setFred(0)
    setResistance(0)
    setAudioUrls([]);
    // setCountdownTime(71000 * freqTop)
    setSelectedCard(card); // Set selected card details
    // console.log(ene)
    // console.log(audioUrls)
    // console.log(cardname)
    //Remove period at the end of each name part if it exists
    const cleanedNames = cardname.map(name => name.replace(/\.$/, ''));
    fullName = cleanedNames.join(' ');
    // console.log(cleanedNames)
    // fullName = cleanedNames
    // console.log(fullName)
    giant = fullName
    energy = ene
    var fullNamey = fullName.replace(/\./g, '');
    getArticle(giant)
    convertToComparative(ene)
    convertToSuperlative(ene)
    // setFullname(giant)
    fullenergy = ene
    // setShowModal(true);
    handleShowSettings(true)
    setPastins(null)
    // setFullenergy(null)
    // setFullname(null)

    // fetchConversation(ene, fullName);
    // setSelectedAudioUrl(audioUrls[0]); // Set the selected audio URL to the first audio URL
    // // Execute the next line of code after waiting for 5 seconds
    // setTimeout(() => {
    //   // Code to be executed after waiting for 5 seconds
    //   console.log("Next line of code executed after waiting for 5 seconds");
    //   setShowModal(true);
    // }, 5000); // 5000 milliseconds = 5 seconds

    const user = auth.currentUser;
    

    if (user) {
      const userid = user.uid
      // const userDocRef = doc(firestore, 'inspire', userid);
      mixpanel.track('handleCardClickUserSigned', {
        message: "card clicked while signed in"
      })
      
      try {
        // console.log("fullenergy: ", fullenergy)
        const fullenergyy = lowercaseFirstLetter(fullenergy)
        // console.log("fullenergyy: ", fullenergyy)
        // console.log("fullname: ", fullName)
        const energygreat = `${fullenergyy}${fullNamey}`;
        // console.log(energygreat)
        // Get the document
        const userDocRef = doc(firestore, 'inspire', energygreat);
        const docSnapshot = await getDoc(userDocRef);
  
        if (docSnapshot.exists()) {
          // Retrieve the 'energygreat' field
          const data = docSnapshot.data();
          // console.log(data)
          const dynamicFieldValue = Object.values(data).flatMap(userComments => 
            Object.values(userComments)
          );
          // console.log("data: ", data)
          // console.log("Energygreat array:", dynamicFieldValue);

          if (dynamicFieldValue) {
            // console.log("Energygreat array:", dynamicFieldValue);
            setPastins(dynamicFieldValue)
          } else {
            // console.log(dynamicFieldValue)
            // console.log("No " + energygreat + " field found in the document.");
          }
        } else {
          // console.log("No document found for the user.");
        }
        //   if (frequency==1){
        //     setAudioUrls([]);
        //     // convertTextToAudio("The Social Energy Contract is an ancient unspoken agreement that forces us to feel emotions or energies, according to the time and type of outside stimulus. For example, being praised by a total stranger. In this exercise, we will release positive energies without waiting for outside permission or stimulus. ")
        //     setAudioUrls([]);
        // }
      } catch (error) {
        // console.error("Error fetching the document: ", error);
      }
    } else {
      // console.log("No user is signed in");
    }
  };

  const handleCloseModal = () => {
    mixpanel.track('modalClosed', {
    })
    // console.log(audioUrls[0])
    // setSelectedAudioUrl(null); // Reset the selected audio URL to null when the modal closes
    // console.log(audioUrls[0])
    setShowNewContent(false)
    setIsCountingDown(false)
    setInspirationText("")
    setFrequency(0)
    setFred(0)
    setResistance(0)
    setIsIncreasing(false)
    setShowModal(false);
    setSelectedOption('')
    setOtherOption('')
    setSelectedSmallCard('Water')
    setRangeValue(0)
    setNotifications(0)
  };

  // Modify useEffect to set audioLoaded to true when audioUrls are updated
// useEffect(() => {
//   setAudioLoaded(true); // Set audioLoaded to true when audioUrls are updated
// }, [audioUrls]);

const handleSelectBackground = async (event) => {
  const value = event.target.value;
  setSelectedBackground(value);
  
  let path;
  switch (value) {
    case '1':
      path = ''; // No sound
      break;
    case '2':
      path = 'https://firebasestorage.googleapis.com/v0/b/troopsong-1a530.appspot.com/o/aum1.mp3?alt=media&token=b809ceb9-f0c1-4eb6-942c-2942d8505024';
      break;
    case '3':
      path = 'https://firebasestorage.googleapis.com/v0/b/troopsong-1a530.appspot.com/o/aum2.mp3?alt=media&token=a4516a86-4721-4a9c-8f3f-48e879783bdc';
      break;
    case '4':
      path = 'https://firebasestorage.googleapis.com/v0/b/troopsong-1a530.appspot.com/o/fire.mp3?alt=media&token=b6bb61ae-6bc3-4c97-b673-41ebcfe08d1e'; 
      break;
    case '5':
      path = 'https://firebasestorage.googleapis.com/v0/b/troopsong-1a530.appspot.com/o/forest.mp3?alt=media&token=e3a6fb74-d07a-4934-996c-0112cdc8b437';
      break;
    case '6':
      path = 'https://firebasestorage.googleapis.com/v0/b/troopsong-1a530.appspot.com/o/guitar.mp3?alt=media&token=28959eed-b3ab-4818-9675-6af8717cebf0';
      break;
    case '7':
      path = 'https://firebasestorage.googleapis.com/v0/b/troopsong-1a530.appspot.com/o/ocean-waves.mp3?alt=media&token=54fdb39e-4dc4-48e6-8d2e-9bc019d7d959'; 
      break;
    case '8':
      path = 'https://firebasestorage.googleapis.com/v0/b/troopsong-1a530.appspot.com/o/mixkit-rain-and-thunder-storm-2390.wav?alt=media&token=b657322c-71c5-499d-9336-da1f8cf71d30';
      break;
    case '9':
      path = 'https://firebasestorage.googleapis.com/v0/b/troopsong-1a530.appspot.com/o/river.mp3?alt=media&token=6a3ae6d5-6da5-4ebd-a4bd-74e099e6538e';
      break;
    case '10':
      path = 'https://firebasestorage.googleapis.com/v0/b/troopsong-1a530.appspot.com/o/white-noise.mp3?alt=media&token=f70f88ad-a8ac-4298-b01d-9b520413e51c'; 
      break;
    default:
      path = '';
  }

  if (path) {
    const audioRef = ref(storage, path);
    const url = await getDownloadURL(audioRef);
    setAudio1Urls([url]);
  } else {
    setAudio1Urls([]);
  }
};
// console.log('counter: ', counter)

    useEffect(() => {
      // Clear any existing interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    // console.log('counter: ', counter)
    
      if (isRunning) {
        intervalRef.current = setInterval(() => {
          setTimer((prev) => {
            if (prev >= duration) {
              // console.log('counter: ', counter)
              setCounter((i) => i + 1);
              return 0;
            }
            // console.log('counter: ', counter)
            return prev + 1;
          });
        }, 1000);
      } else{
        clearInterval(intervalRef.current);
      }
      // Clear the interval on component unmount or when isRunning changes
      return () => clearInterval(intervalRef.current);
    }, [isRunning]);


useEffect(() => {
  const interval = setInterval(() => {
    setAnimate(false);
    setTimeout(() => {
      setIndex((prevIndex) => (prevIndex + 1) % energyList.length);
      setAnimate(true);
    }, 500); // Duration of the animation
  }, 3500); // Change energy every 30 seconds + 0.5 seconds for animation

  return () => clearInterval(interval); // Cleanup interval on component unmount
}, []);

useEffect(() => {
  setCurrentEnergy(energyList[index]);
}, [index, energyList]);


useEffect(() => {
  if(isHuman){
    setCorn('Humans');
  }else{
    setCorn('Nature');
  }
}, [isHuman]);

useEffect(() => {
  if (isIncreasing) {
    const interval = setInterval(() => {
      setFrequency(prevFrequency => {
        const newFrequency = prevFrequency + 1;
        if (newFrequency <= ranges[rangeValue]) {
          if (newFrequency === 1) {
            clearInterval(interval);
            setTimeout(() => {
              setFrequency(1);
                       }, (4260000/2) * ranges[rangeValue]); // Next increment after initial 71 seconds
                      // }, (71000/2) * ranges[rangeValue]); // Next increment after initial 71 seconds
          }
          return newFrequency;
        } else {
          clearInterval(interval);
          return ranges[rangeValue];
        }
      });
    }, frequency === 0 ? 3000 : (71000/5)); // Initial delay of 3 seconds for frequency 0, then 71 seconds
    return () => clearInterval(interval);
  }
}, [isIncreasing, frequency, rangeValue]);



useEffect(() => {
  if (frequency === 1) {
    setFred(1);
    setAudioUrls([]);
    // console.log(frequency);
    setAudioUrls([]);
    if (selectedPower) {
      convertTextToAudio(explanations[selectedPower], subfreq);
    }else{
      // console.log('selectedPower: ', selectedPower)
    }
  } else if (frequency > 1 && frequency <= ranges[rangeValue]) {
    setFred(frequency);
    setAudioUrls([]);
    // console.log(frequency);
    if (selectedPower) {
      convertTextToAudio(explanationsmorethanone[selectedPower], subfreq);
    }else{
      // console.log('selectedPower: ', selectedPower)
    }
  }
}, [frequency, selectedPower, rangeValue]);

useEffect(() => {
  if (isCountingDown & frequency > 0) {
    timerRef.current = setInterval(() => {
      setCountdownTime((prevTime) => {
        if (prevTime <= 1000) {
          clearInterval(timerRef.current);
          setIsRestart(true); // Set restart state when countdown reaches 0
          return 0;
        }
        return prevTime - 1000;
      });
    }, 1000);
  } else {
    clearInterval(timerRef.current);
  }

  return () => clearInterval(timerRef.current);
}, [isCountingDown, frequency]);

useEffect(() => {
  // console.log('onEnded1')
  // Event handler function to avoid anonymous functions in event listeners
  // const onEndedHandler = (index) => () => handleAudioEnded(index);
  const onPlayHandler = (index) => () => handlePlay(index);
  const onPauseHandler = (index) => () => handlePause(index);


  // Set loop for all audio elements when audioUrls change
  audioRefs.current.forEach((audio, index) => {
    // console.log('onEnded2')

    if (audio) {
      audio.loop = true; // Disable native loop
      audio.playbackRate = 0.90; // Set playback rate to 0.90
      // audio.addEventListener('ended', onEndedHandler(index));
      audio.addEventListener('play', onPlayHandler(index));
      audio.addEventListener('pause', onPauseHandler(index));
      // console.log('onEnded3')
      
    }
    
  });

  // Clean up event listeners on component unmount
  return () => {
    audioRefs.current.forEach((audio, index) => {
      if (audio) {
        // audio.removeEventListener('ended', onEndedHandler(index));
        audio.removeEventListener('play', onPlayHandler(index));
        audio.removeEventListener('pause', onPauseHandler(index));

      }
    });
  };


}, [audioUrls]);

// useEffect(() => {
//   if (!audioContextRef.current) {
//     audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
//     gainNodeRef.current = audioContextRef.current.createGain();
//     biquadFilterRef.current = audioContextRef.current.createBiquadFilter();
//     biquadFilterRef.current.type = 'highpass';
//     gainNodeRef.current.connect(audioContextRef.current.destination);
//     biquadFilterRef.current.connect(gainNodeRef.current);
//     gainNodeRef.current.gain.value = 1;
//   }
// }, []);

useEffect(() => {
  
  // if (biquadFilterRef.current) {
  //   biquadFilterRef.current.frequency.value = subfreq;
  // }
  setAudioUrls([])
  // convertTextToAudio(searchText, subfreq)
  // playAudioSequentially(audioUrl, subfreq, 0.90)
}, [subfreq]);

// const handleAudioEnded = (index) => {
//   // setLoopCount((prevLoopCount) => prevLoopCount + 1);
//   // console.log(`Audio at index ${index} has looped. Total loops: ${loopCount + 1}`);
//   console.log(`Audio at index ${index} has looped.`);
// };

const handlePlay = (index) => {
  // starts counting
  // console.log(`Play button clicked for audio at index ${index}`);
  setIsRunning(true);

};

const handlePause = (index) => {
  // pause counting
  // console.log(`Pause button clicked for audio at index ${index}`);
  setIsRunning(false);
  clearInterval(intervalRef.current);
};

const handleStartStopClick = () => {
  setIsPlaying(!isPlaying);
  handleStartStop();
};

const formatTime = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};




// *******get hints
// useEffect(() => {
//   // Listen for changes in the Firestore collection
//   // if (searchText != ""){
//   const unsubscribe = db.collection("greats").onSnapshot((snapshot) => {
//     const newHints = snapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data()
//     }));
//     // console.log('newHints:', newHints)
//     setHints(newHints);
//   });

//   // Clean up listener
//   return () => unsubscribe();
// // }
// }, []);

useEffect(() => {
 const enenes = [
      "Wealthy", "Sharp", "Rare", "Charitable", "Detailed", "Relatable", 
      "Lovely", "Cute", "Honest", "Insightful", "Gritty", "Giving", 
      "Seasonal", "Metamorphic", "Authoritative", "Iconic", "Humorous", 
      "Eccentric", "Smooth", "Public speaker", "Leisurely", "Dangerous", 
      "Calming", "Diverse", "Intimidating", "Stylish", "Timeless", "Witts", 
      "Powerful", "Visionary", "Playful", "Affable", "Consuming", "Productive", 
      "Sorrowful", "Dedicated", "Radiant", "Influencial", "Delicate", "Natural", 
      "Athletic", "Gentle", "Transformative", "Controversial", "Abundant", 
      "Delicious", "Laid-back", "Talented", "Analytical", "Bold", "Likable", 
      "Sturdy", "Friendly", "Tragic", "Moving", "Creative", "Capricious", 
      "Nourishing", "Blue", "Wise", "Developing", "Theological", "Grand", 
      "Indispensable", "Keen", "Fast", "Energetic", "Persuasive", "Hidden", 
      "Aromatic", "Focus", "Soothing", "Compassionate", "Attractive", "Bright", 
      "Adaptable", "Simple", "Hardworking", "Reassuring", "Sophisticated", 
      "Motivational", "Noble", "Shifting", "Generous", "Gregarious", "Sagacious", 
      "Ancient", "Vital", "Scholarly", "Honorable", "Social", "Healing", 
      "Articulate", "Fine", "Majestic", "Breezy", "Unpredictable", "Touching", 
      "Breaking Records", "Disputed", "Deep", "Endangered", "Leader", "Rough", 
      "Comforting", "Thoughtful", "powerful", "Eloquent", "Heated", "Holy", 
      "Receptive", "Enigmatic", "Azure", "Warm", "Towering", "Approachable", 
      "Virtuous", "Infinite", "Diplomatic", "Disciplined", "Powdery", "Devouring", 
      "Poetic", "Empathetic", "Trailblazer", "Candid", "Driven", "Resolute", 
      "Committed", "Loyal", "Record-breaking", "Fierce", "Warmth", "Intellectual", "Tactical", 
      "Dark", "Dignified", "Competitive", "Cadenced", "Outspoken", "Smart", 
      "Flamboyant", "Small", "Vibrant", "Sustaining", "Fun-loving", "Romantic", 
      "Wry", "Public Speaking", "Tall", "Verdant", "Theatrical", "Daring", 
      "Charismatic", "Imaginative", "Resilient", "Regal", "Durable", "Sexy", 
      "Periodic", "Expressive", "Prolific", "Unapologetic", "Provocative", 
      "Engaging", "Kind", "Fluid", "Expansive", "Observational", "Sleek", 
      "Ethereal", "Invigorating", "Uncommon", "Heartfelt", "Impartial", 
      "Theoretical", "Perilous", "Intelligent", "Inventive", "Reflective", 
      "Strategic", "Unstoppable", "Flexible", "Important", "Inspiring", 
      "Caring", "Groundbreaking", "Compelling", "Crucial", "Significant", 
      "Glamorous", "Plentiful", "Cheerful", "Ethical", "Artistic", "Principled", 
      "Respected", "Revolutionary", "Skilled", "Self Challenges", "Brave", 
      "Rich", "Scenic", "Literary", "Genius", "Versatile", "Brilliant", 
      "Mature", "Environmentalist", "Magnetic", "Unstable", "Tenacious", 
      "Dramatic", "Emotional", "Graceful", "Speaker", "Distinctive", 
      "Trailblazing", "Prodigious", "Satisfying", "Peaceful", "Well-known", 
      "Empowering", "Hot", "High", "Impactful", "Witt", "Fragrant", "Confident", 
      "Wide", "Viral", "Sage", "Invisible", "Mild", "Handsome", "Clever", 
      "Courageous", "Immense", "Hard", "Slow", "Intense", "Marketing", 
      "Aesthetic", "Philanthropic", "Strong willed", "Picturesque", 
      "Successful", "Pure", "Refined", "Briny", "Popular", "Swift", 
      "Passionate", "Resilient", "Skillful", "Dynamic", "Endearing", 
      "Varied", "Inspirational", "Long-lasting", "Persistent", "Rugged", 
      "Orator", "Convincing", "Knowledgeable", "Quirky", "Soft", "Fearless", 
      "Nonviolent", "Clean", "Tiny", "Melodic", "Essential", "Pensive", 
      "Selfless", "Legendary", "Nimble", "Happy", "Observant", "Broad-minded", 
      "Huge", "Negotiable", "Fragile", "Elegant", "Lyricist", "Green", 
      "Fit", "Quiet", "Fearsome", "Upright", "Scorching", "Steady", 
      "Commanding", "Refreshing", "Wealth", "Faithful", "Saintly", "Diligent", 
      "Harmonious", "Unyielding", "Grainy", "Elusive", "Gifted", "Threatened", 
      "Life-sustaining", "Techy", "Ever-changing", "Agile", "Eternal", 
      "Forceful", "Enormous", "Neutral", "Magnificent", "Pace setter", 
      "Solid", "Dominant", "Sacred", "Imposing", "Hilarious", "Unique", 
      "Altruistic", "Captivating", "Textured", "Tasty", "Mysterious", 
      "Ambitious", "Strong", "Advocative", "Adaptable", "Edgy", "Soulful", 
      "Determined", "Creativity", "Dry", "Salty", "Tough", "Silent", 
      "Coarse", "Constant", "Growing", "Aristocratic", "Esteemed", 
      "Formidable", "Devoted", "Masterful", "Philosophical", "Fulfilling", 
      "Scientific", "Hard working", "Open", "Driven", "Vulnerable", 
      "Witty", "Musical", "Funny", "Ingenious", "Life-giving", "Multifaceted", 
      "Modest", "Relaxed", "Poignant", "Pioneering", "Enduring", "Profound", 
      "Endless", "Serene", "Adventurous", "Rhythmic", "Social Media Hacking", 
      "Non-judgemental", "Changing", "Record Breaking", "Lively", "Perceptive", 
      "Old", "Vast", "Adorable", "Airy", "Beautiful", "Heartwarming", 
      "Colorful", "Forward-thinking", "Restorative", "Kind-hearted", 
      "Calm", "Innovative", "Firm", "Genuine", "Fervent", "Principled"
  ]
  setHints(enenes);
  // Clean up listener
  // return () => enenes;
}, [])

 

  const generateAffirmations = debounce(async () => {
    mixpanel.track('generateAffirmation', {
      // user: userEmail,
      // energy: ene
      text:searchText
    })
    const openai = new OpenAI({apiKey:'sk-proj-5Xc3ZBosQlmDs8LndaxtT3BlbkFJiDpsq70OVKc3A7rkkEYr', dangerouslyAllowBrowser: true});
    // setLoading(true);
    try {
      setIsLoading(true);
      // console.log(myDesire)
        const completion = await openai.chat.completions.create(
          // 'https://api.openai.com/v1/chat/completions',
          {
          messages: [
                    {
                      "role": "user",
                      // "content": `write 5 different compliments people have likely given to "${giant}". Say them enthusiastically.`
                      // "content": `write 1 compliment a psychologist will likely give to "${giant}" about how the way "${giant}" thinks about "${energy}" has helped them with their "${energy}". say it in the words of a 5 year old and Mention the "${giant}" in the compliment.
                      // finally, replace the name "${giant}" with 'Osay'
                      // `
                      //  "content": `write 1 quote of "${giant}" about "${energy}". convert the quote into a compliment to 'Osay'
                      // `
                      //  "content": `write 1 quote of "${giant}" about "${energy}". convert the quote into a compliment to 'Osay'
                      // `
                      // "content": `
                      // from a person's action you can predict the type of thoughts the person has.
                      // For example, for someone that seems comfortable speaking to an audience, the person will have thoughts such as 'i might feel pressured at the beginning but I ignore it and afterwards, speaking to people comes naturally and i'm taking advantage of this power to push through that I have to be able to influence people'.

                      // The task, is for you to come up with thoughts in which "${giant}" likely had that made "${giant}" work on their "${energy}" or helped them develope strenght in "${energy}", highlight the doubts they had (if relevant) and how they pushed forward to create the "${energy}" we see or know them for. You can draw ideas from their quotes, interviews or any event that happened where they showed ${energy}.

                      // let it be short not more than 5 sentences.
                      // write as 3rd person as if "${giant}" thinks in the expression of an emotional energy healer, sharman or guru.
                      // but never say 'as an energy healer or guru.
                      // Finally: Change the name "${giant}" to a new name 'Osay'
                      // `
                      // write as a 3rd person in the words of an energy healer or guru but never say 'as an energy healer or guru'.
                      // Finally: Change the name "${giant}" to a new name 'Osay'

                      // "content": 
                      // `
                      // from a person's action you can predict the type of thoughts the person has.
                      // For example, for someone that seems comfortable speaking to an audience, the person will have thoughts such as 'i might feel pressured at the beginning but I ignore it and afterwards, speaking to people comes naturally and i'm taking advantage of this power to push through that I have to be able to influence people'.

                      // The task, is for you to use the goal provided below to come up with thoughts in which "${giant}" likely had that made "${giant}" work on their "${energy}" or helped them develope strenght in "${energy}", highlight the doubts they had (if relevant) and how they pushed forward to create the "${energy}" we see or know them for. You can draw ideas from their quotes, interviews or any event that happened where they showed ${energy}.
                      // Goal: "${myDesire}"

                      // convert the result into a compliment given by an energy healer or guru to "${giant}".
                      // Finally: Change the name "${giant}" to a new name 'Osay'
                      // `
                      // "content": 
                      // // If ${searchText} is releated to the body: The task, is for you to Generate affirmations using the expert knowledge of a medical doctors and at the same time one of the best energy healers. Use your knowledge of the body and the cells responsible for acheiving a result.
                      // `The task, is for you to Generate affirmations using the expert knowledge of an expert knowing exactly what it takes to acheivce ${searchText} and at the same time understanding how energy healering works. 
                      // The user is not the expert you're the expert, you're using your expert knowledge to generate effective affirmations for the user
                      // Generate 5 affirmations for ${searchText}
                      // Don't add any other text aside the affirmations, don't number them either
                      // `
                      // "content": 
                      // `The task, is for you to Generate 20 different affirmations of different styles including writing a short story about how 'I' manifested  ${searchText} or complementing me about ${searchText} using the expert knowledge of an expert knowing exactly what it takes to acheivce ${searchText} and at the same time understanding how energy healing works. 
                      // The user is not the expert you're the expert, you're using your expert knowledge to generate effective affirmations for the user
                      // Number the affirmations
                      // Don't write an introduction or a title only write the number and the affirmation
                      // `

                      content: `
                            The task is for you to generate between 5 to 10 affirmations using any of the following styles:
                            If ${searchText} is about the body of the user, using the expert knowledge of a human anatomist and energy healer, using the styles below create affirmations that activates or that's focused on the cells, organs or parts of the body that's responsible for acheiving ${searchText}. 
                            1. Receiving Compliment: Write a short compliment to the user about ${searchText} in a way that makes the user feel like they have already achieved ${searchText}. The conversation must be positive and very exciting. The speaker is either talking to the user or someone else.
                            2. Affirmation: Write a strong affirmation about ${searchText} using the knowdge it takes to acheive ${searchText}. 
                            3. Divine Law-of-Multiplication: Write an affirmation telling the user they are x times more ${searchText} than something in nature, such as any elements, celebrity, animal, plant, for example Rock posses strength etc that possess or exhibit the characteristics needed to achieve ${searchText}. x is a number between 1 and 10. 
                            4. Quantum Jumping: Write an affirmation about ${searchText} asking the person to either feel, imagine, remember, acknowledge or realize that a version of them in a parallel universe already acheived ${searchText}. Write like the person is writing it themselves using first person e.g 'I connect with the energy of the parallel version of myself'
                            5. Small-Step-Progress: Write an affirmation about the user acheiving the first small steps of ${searchText} using the knowdge it takes to acheive ${searchText}.
                            6. I-Am-Nature: Write an affirmation making the user understand that because their body and mind is a part of nature then they possess everything in nature. Therefore, they already have ${searchText}.
                            7. Feeling-Is-The-Key: Convince and encorage the user to only try to activate in their body the feeling chemicals that will be released by possessing ${searchText} and because they already feel it they already possess it. Avoid using words like 'As if I already' instead say 'I have already'
                            8. My-Next-Step: Write a step the user can take to acheive ${searchText} in form of an affirmation.

                            The goal is to put the user in a state that they have already acheived ${searchText}.
                            Number each response sequentially and start by writing which style it is.  for example:
                            1. **Affirmation:**
                            "I nurture my scalp and hair follicles with love and care, promoting growth and fullness. My hair is becoming fuller and longer every day."
                            2. **Compliment:**
                            "Your hair is absolutely stunning! It's so full and long, just like you've always dreamed. You're glowing with confidence!"

                            finally, detect the language with which ${searchText} is written and use it as the output language. If you cant detect the language output in English.
                          `


                    }
                  ],
          temperature: 0.7,
          max_tokens: 600,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
          // model: "gpt-3.5-turbo-0125",
          model: "gpt-4o",
        },
      );
        // console.log(completion.choices[0]);
        console.log(completion.choices[0].message.content);
        const conversationText = completion.choices[0].message.content;
        // console.log("conversation: ", conversationText)
        // setSearchText(conversationText)

        // Split the response into individual lines
        // const lines = conversationText.split('\n').filter(line => line.trim() !== '');
        // Regular expression to match the numbered lines with style and affirmation
        // const regex = /^\d+\.\s\*\*(.+?)\*\*:\s*([\s\S]+)/m;
        // Initialize an array to hold the parsed affirmations
        // / Split the text into paragraphs based on the numbered format
        const paragraphs = conversationText.split(/\d+\.\s+/).filter(Boolean);
        const parsedAffirmations = [];


        paragraphs.forEach(paragraph => {
          const [style, affirmation] = paragraph.split('**').map(part => part.trim()).filter(Boolean);
          if (style && affirmation) {
            parsedAffirmations.push({ style, affirmation });
          }
        });
        
        // Use a regular expression to match lines starting with a number followed by a period
        // const affirmations = conversationText.match(/\d+\.\s(.+)/g).map(line => line.replace(/^\d+\.\s/, ''));
        console.log("parsedAffirmations: ", parsedAffirmations)
        setAffirmations(parsedAffirmations)
        // console.log("const affirmations = [");
        // affirmations.forEach(affirmation => {
        //   console.log(`  "${affirmation}",`);
        // });
        // console.log("];");

        // conversationText = conversationText.replace(/\n\s*\n/g, '');
        // setConversation(conversationText)
        // convertTextToAudio(conversationText)
        // handleConversationTransfer(conversationText)
        // handleCloseModal()
        // navigate('/play-audio', { state: { conversationText } });

    } catch (error) {
      console.error('Error fetching conversation:', error.message);
      // Handle specific error statuses
      // if (error.response && error.response.status === 429) {
      //   alert('Rate limit exceeded. Please wait and try again.');
      // } else {
      //   alert('An error occurred while generating affirmations.');
      // }
    }
    finally{
      setIsLoading(false);
      setShowGenerated(true)
    }
  }, 1000); // Debounce delay of 1000 milliseconds
  
  const saveVote = async () => {
    
    const user = auth.currentUser;
    // if (!selectedOption) handleCloseModal();

    if (user) {
      const userid = doc(firestore, "users", user.uid)
      try {
        await updateDoc(userid , {
          vote: selectedOption
        });
        // console.log('New Energy Successfully Created!');
        handleCloseModal()
      } catch (error) {
        // console.error("Error saving Energy: ", error);
        // alert('Error uploading vote.');
      }
    }
    else{
      setShowSignInButton(true); // Show the sign-in modal if user is not signed in
      // console.log("No user is signed in");
    }
    // console.log(selectedOption)
    
  };

  const saveInspiration = async () => {
    
    const user = auth.currentUser;
    const energygreat = `${fullenergy}${fullName}`;
    mixpanel.track('clickedSaveIntentionDunnoIfSignedIn', {
      insight: inspirationText
    })
  
    if (user) {
      // const inspirationText = inspirationText; // Replace with your actual inspirationText
      const userDocRef = doc(firestore, 'inspire', user.uid);
      mixpanel.track('clickedSaveIntentionSignedIn', {
        userName: user.displayName,
        userEmail:user.email,
        energy: fullenergy,
        name: fullName,
        insight: inspirationText
      })
  
      try {
        // Check if the document exists
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          // If the document exists, update the array field
          await updateDoc(userDocRef, {
            [energygreat]: arrayUnion(inspirationText)
          });
        } else {
          // If the document does not exist, create it with the array field
          await setDoc(userDocRef, {
            [energygreat]: [inspirationText]
          });
        }
        // console.log("Inspiration text saved successfully");
        try {
          // Get the document
          const docSnapshot = await getDoc(userDocRef);
    
          if (docSnapshot.exists()) {
            // Retrieve the 'energygreat' field
            const data = docSnapshot.data();
            const dynamicFieldValue = data[energygreat];
  
            if (dynamicFieldValue) {
              // console.log("Energygreat array:", dynamicFieldValue);
              setPastins(dynamicFieldValue)
            } else {
              // console.log("No 'energygreat' field found in the document.");
            }
          } else {
            // console.log("No document found for the user.");
          }
        } catch (error) {
          // console.error("Error fetching the document: ", error);
        }

      } catch (error) {
        // console.error("Error saving inspiration text: ", error);
      }
    } else {
      setShowSignInButton(true); // Show the sign-in modal if user is not signed in
      // console.log("No user is signed in");
    }
  }

  const getAudibility = (frequency) => {
    if (frequency >= 17500) {
      return 'subliminal';
    } else if (frequency >= 14000) {
      return 'slightly audible';
    } else if (frequency >= 1000) {
      return 'still audible';
    } else {
      return 'audible';
    }
  };

  const handleFrequencyChange = (e) => {
    const newFreq = Number(e.target.value);
    // console.log(newFreq)
    setSubfreq(newFreq);
    setAudibility(getAudibility(newFreq));

    if (biquadFilterRef.current) {
      biquadFilterRef.current.frequency.value = newFreq;
    }
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOtherChange = (event) => {
    setOtherOption(event.target.value);
  };

  const handleNext = () =>{
    mixpanel.track('clickedNext', {
      insight: inspirationText
    })

    setIsIncreasing(false)
    setIsCountingDown(false)
    setShowNewContent(true);

  }

  // Function to convert AudioBuffer to MP3
const convertAudioBufferToMP3 = (audioBuffer, subfreq) => {
  return new Promise((resolve, reject) => {
    const sampleRate = audioBuffer.sampleRate;
    const channels = audioBuffer.numberOfChannels;
    const samples = audioBuffer.length;
    const mp3Encoder = new Lame.Mp3Encoder(channels, sampleRate, 128); // 128kbps bitrate

    let mp3Data = [];
    const buffer = audioBuffer.getChannelData(0); // Assuming mono audio for simplicity

    // Encode the audio buffer data
    for (let i = 0; i < buffer.length; i += 1152) {
      const chunk = buffer.subarray(i, i + 1152);
      mp3Data.push(mp3Encoder.encodeBuffer(chunk));
    }
    
    mp3Data.push(mp3Encoder.flush()); // Finish encoding

    // Combine all MP3 chunks into a single Blob
    const mp3Blob = new Blob(mp3Data, { type: 'audio/mp3' });
    resolve(mp3Blob);
  });
};

  const saveInsight = async () => {
    if(inspirationText==""){
      alert("Write an insight you'll like to remember")
      return;
    }

    const user = auth.currentUser;
    
    var fullNamey = fullName.replace(/\./g, '');
    const energygreat = `${fullenergy}${fullNamey}`;
    mixpanel.track('clickedSaveInsightDunnoIfSignedIn', {
      insight: inspirationText
    })
    const userid = user.uid
    const photo = user.photoURL

    if (user) {
      // const inspirationText = inspirationText; // Replace with your actual inspirationText
      // const userDocRef = doc(firestore, 'inspire', user.uid);
      const userDocRef = doc(firestore, 'inspire', energygreat);
      var date = new Date().toLocaleDateString()
      var time = new Date().toLocaleTimeString()

      mixpanel.track('clickedSaveInsightSignedIn', {
        userName: user.displayName,
        userEmail:user.email,
        energy: fullenergy,
        name: fullName,
        insight: inspirationText
      })

      try {
        // Check if the document exists
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          // If the document exists, update the array field
          await updateDoc(userDocRef, {
            [userid]: arrayUnion({text: inspirationText, date: date, time: time, photo: photo})
          });
        } else {
          // If the document does not exist, create it with the array field
          await setDoc(userDocRef, {
            [userid]: [{text: inspirationText, date: date, time: time, photo: photo}]
          });
        }
        // console.log("Inspiration text saved successfully");
        setInspirationText("")
        try {
          // Get the document
          const docSnapshot = await getDoc(userDocRef);
    
          if (docSnapshot.exists()) {
            // Retrieve the 'energygreat' field
            const data = docSnapshot.data();
            // console.log("data: ", data)
            const dynamicFieldValue = Object.values(data).flatMap(userComments => 
              Object.values(userComments)
            );
  
            if (dynamicFieldValue) {
              // console.log("Energygreat array:", dynamicFieldValue);
              setPastins(dynamicFieldValue)
            } else {
              // console.log("No 'energygreat' field found in the document.");
            }
          } else {
            // console.log("No document found for the user.");
          }
        } catch (error) {
          // console.error("Error fetching the document: ", error);
        }

      } catch (error) {
        // console.error("Error saving inspiration text: ", error);
      }

    }else{
      setShowSignInButton(true); // Show the sign-in modal if user is not signed in
      return;
    }


    // setIsIncreasing(false)
    // setIsCountingDown(false)
    
    // const fullenergyy = lowercaseFirstLetter(fullenergy)
    // const energygreat = `${fullenergyy}${fullname}`;
    // console.log("fullnamey: ", energygreat)

    // mixpanel.track('handleNext', {
    //   message: "nextButton clicked dunno if signed in"
    // })
  
  };



  const getPopulation = async (text) =>{

    const user = auth.currentUser;
    // const userid = user.uid
    // const photo = user.photoURL

    if (!user) {
      setShowSignInButton(true); // Show the sign-in modal if user is not signed in
      return;
    }

    // if(subIndexG == null){

    // }

    
    try {
      
      const user = auth.currentUser;
      const userid = user.uid
      // console.log('userid: ', userid)
      // Get reference to the user's document in the 'users' collection
      const userDocRef = doc(db, 'users', userid);
  
      // Get the document snapshot
      const userDocSnap = await getDoc(userDocRef);
  
      // Check if the document exists
      if (userDocSnap.exists()) {
        // Get the 'subliminals' field data
        const subliminalsData = userDocSnap.data().subliminals;
        // console.log(subliminalsData)
  
        // Check if 'tags' field exists in the 'subliminals' object
        if (subliminalsData && subliminalsData[subIndexG].hasOwnProperty('population')) {
          // console.log('Population field exists:', subliminalsData[subIndexG].population);
          // console.log('population:', subliminalsData[subIndexG].mp3length);

          // return true; // Field exists
          //**** */undo
          // convertTextToAudio(searchText, subfreq);
          setPopulation(subliminalsData[subIndexG].population.figure)
          setPopExplanation(subliminalsData[subIndexG].population.explanations)
          setTriggerPopulation(true)
          setIsLoading(false);

        } else {
          // console.log('Population field does not exist.');
          // return false; // Field does not exist
          console.log('e wan create')
          // get tag
          const openai = new OpenAI({apiKey:'sk-proj-5Xc3ZBosQlmDs8LndaxtT3BlbkFJiDpsq70OVKc3A7rkkEYr', dangerouslyAllowBrowser: true});

          try {
            // Prepare the prompt for OpenAI Completions API
            const prompt = `
              Considering that the total estimated number of people that has lived dead and alive is 117 billion. Using current statistics percentages, 
              give a low estimate of the number of people dead or alive that have acheived the input text:
              Rule: Do not give the number of people that has expressed the desire or sort to acheive the input text as this isnt the goal. 
              If input text is about anything in the human body, give the number of people that already naturally possess the look or desire and are not looking for remedy because they were born with it 
              i.e don't look for statistics of people that used the remedy.  For example: If the input text is 'I want to grow taller', find statistics of people who are taller not people who have the desire to be taller.  
              If it has nothing to do with human body, give the number of people that has actually done it. Also, find the main need of the user in the input text, do not add to the analysis the method they want to use in acheive the desire e.g manifestation, affirmation, visualisation etc. 
              Only analyse main desire or result the user is looking for.
              Input text: "${text}"
              Respond with only a figure, 1 short sentence about what the figure represents and another sentence about how you came about the figure. Respond with numbers like 3000000000 instead of words like 3 billion.
              result format: let the response come in a dictionary e.g {figure:"10000000", explanations: "This figure represents the estimated number..."}
            `;

            // Call OpenAI API to get relevant tags
            const response = await openai.chat.completions.create({
              // 'https://api.openai.com/v1/completions',
              messages:[{
                "role": "user",
                "content": prompt,
            }],
              model:"gpt-4o-mini",
            });

            console.log('population:', response.choices[0].message.content)

            // Convert the string to a JavaScript object
            const parsedResponse = JSON.parse(
              response.choices[0].message.content
                .replace(/figure:/, '"figure":') // Add double quotes around keys
                .replace(/explanations:/, '"explanations":') // Add double quotes around keys
            );

            // Parse the response to extract the tags
            const populationFromAPI = parsedResponse.figure
              // .trim()
              // .split(',')
              // .map(tag => tag.trim());

            // Set the selected tags in the state
            setPopulation(populationFromAPI);
            setPopExplanation(parsedResponse.explanations)
            // console.log('tagsFromAPI: ', tagsFromAPI)

            // Update the subliminals array with the new tags
            const updatedSubliminals = [...subliminalsData];
            updatedSubliminals[subIndexG] = {
              ...updatedSubliminals[subIndexG],
              population: parsedResponse
            };

            // Save the updated subliminals array back to Firestore
            await updateDoc(userDocRef, {
              subliminals: updatedSubliminals
            });

            // Once tags are selected, call the convertTextToAudio function
            // convertTextToAudio(searchText, subfreq);
            setSubPopulation(populationFromAPI)
            setTriggerPopulation(true)
            setIsLoading(false);

          } catch (error) {
            console.error('Error fetching tags from OpenAI:', error);
            setIsLoading(false);

          } finally {
            setIsLoading(false);
            setTriggerPopulation(true)
          }

        }
      } else {
        console.log('Document does not exist.');
        setIsLoading(false);
        return false; // Document does not exist
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      setIsLoading(false);
      return false; // Handle error
    }

  }

  // const getPopulation = async (text) =>{

  //   const user = auth.currentUser;
  //   // const userid = user.uid
  //   // const photo = user.photoURL

  //   if (!user) {
  //     setShowSignInButton(true); // Show the sign-in modal if user is not signed in
  //     return;
  //   }

 
  //   setIsLoading(true);
  //   console.log('subIndex: ', subIndex)
  //   console.log('subIndexG: ', subIndexG)
  //   if(subIndexG == null){
  //     // get tag
  //     const openai = new OpenAI({apiKey:'sk-proj-5Xc3ZBosQlmDs8LndaxtT3BlbkFJiDpsq70OVKc3A7rkkEYr', dangerouslyAllowBrowser: true});

  //     try {
  //       // Prepare the prompt for OpenAI Completions API
  //       const prompt = `
  //         Considering that the total estimated number of people that has lived dead and alive is 117 billion. Using current statistics percentages, 
  //         give a low estimate of the number of people dead or alive that have acheived the input text:
  //             Input text: "${text}"
  //             Respond with only a figure and no explanations.
  //             if the input text contains nonhuman characteristics output 0
  //       `;

  //       // Call OpenAI API to get relevant tags
  //       const response = await openai.chat.completions.create({
  //         // 'https://api.openai.com/v1/completions',
  //         messages:[{
  //           "role": "user",
  //           "content": prompt,
  //       }],
  //         model:"gpt-4o-mini",
  //       });

  //       console.log('population:', response.choices[0].message.content)

  //       // Parse the response to extract the tags
  //       const populationFromAPI = response.choices[0].message.content
  //         // .trim()
  //         // .split(',')
  //         // .map(tag => tag.trim());

  //       // Set the selected tags in the state
  //       setPopulation(populationFromAPI);
  //       setSubPopulation(populationFromAPI)
  //       // console.log('tagsFromAPI: ', tagsFromAPI)
  //       setTriggerPopulation(true)
  //       // Once tags are selected, call the convertTextToAudio function
  //       // convertTextToAudio(searchText, subfreq);
  //       setIsLoading(false);


  //     } catch (error) {
  //       console.error('Error fetching tags from OpenAI:', error);
  //       setIsLoading(false);
  //     }
  //     return
  //   }
    
  //   try {
      
  //     const user = auth.currentUser;
  //     const userid = user.uid
  //     // console.log('userid: ', userid)
  //     // Get reference to the user's document in the 'users' collection
  //     const userDocRef = doc(db, 'users', userid);
  
  //     // Get the document snapshot
  //     const userDocSnap = await getDoc(userDocRef);
  
  //     // Check if the document exists
  //     if (userDocSnap.exists()) {
  //       // Get the 'subliminals' field data
  //       const subliminalsData = userDocSnap.data().subliminals;
  //       // console.log(subliminalsData)
  
  //       // Check if 'tags' field exists in the 'subliminals' object
  //       if (subliminalsData && subliminalsData[subIndexG].hasOwnProperty('population')) {
  //         console.log('Population field exists:', subliminalsData[subIndexG].population);
  //         // console.log('population:', subliminalsData[subIndexG].mp3length);

  //         // return true; // Field exists
  //         //**** */undo
  //         // convertTextToAudio(searchText, subfreq);
  //         setTriggerPopulation(true)
  //         setIsLoading(false);


  //       } else {
  //         console.log('Population field does not exist.');
  //         // return false; // Field does not exist
  //         console.log('e wan create')
  //         // get tag
  //         const openai = new OpenAI({apiKey:'sk-proj-5Xc3ZBosQlmDs8LndaxtT3BlbkFJiDpsq70OVKc3A7rkkEYr', dangerouslyAllowBrowser: true});

  //         try {
  //           // Prepare the prompt for OpenAI Completions API
  //           const prompt = `
  //             Considering that the total estimated number of people that has lived dead and alive is 117 billion. Using current statistics percentages, 
  //             give a low estimate of the number of people dead or alive that have acheived the input text:
  //             Input text: "${text}"
  //             Respond with only a figure and no explanations.
  //             if the input text contains nonhuman characteristics output 0
  //           `;

  //           // Call OpenAI API to get relevant tags
  //           const response = await openai.chat.completions.create({
  //             // 'https://api.openai.com/v1/completions',
  //             messages:[{
  //               "role": "user",
  //               "content": prompt,
  //           }],
  //             model:"gpt-4o-mini",
  //           });

  //           console.log('population:', response.choices[0].message.content)

  //           // Parse the response to extract the tags
  //           const populationFromAPI = response.choices[0].message.content
  //             // .trim()
  //             // .split(',')
  //             // .map(tag => tag.trim());

  //           // Set the selected tags in the state
  //           // setSelectedTags(tagsFromAPI);
  //           // console.log('tagsFromAPI: ', tagsFromAPI)

  //           // Update the subliminals array with the new tags
  //           const updatedSubliminals = [...subliminalsData];
  //           updatedSubliminals[subIndexG] = {
  //             ...updatedSubliminals[subIndexG],
  //             population: populationFromAPI
  //           };

  //           // Save the updated subliminals array back to Firestore
  //           await updateDoc(userDocRef, {
  //             subliminals: updatedSubliminals
  //           });

  //           // Once tags are selected, call the convertTextToAudio function
  //           // convertTextToAudio(searchText, subfreq);
  //           setSubPopulation(populationFromAPI)
  //           setTriggerPopulation(true)
  //           setIsLoading(false);

  //         } catch (error) {
  //           console.error('Error fetching tags from OpenAI:', error);
  //           setIsLoading(false);

  //         } finally {
  //           setIsLoading(false);
  //           setTriggerPopulation(true)
  //         }

  //       }
  //     } else {
  //       console.log('Document does not exist.');
  //       setIsLoading(false);
  //       return false; // Document does not exist
  //     }
  //   } catch (error) {
  //     console.error('Error fetching document:', error);
  //     setIsLoading(false);
  //     return false; // Handle error
  //   }

  // }

  const callOpenAIForTags = async (text) => {

    const user = auth.currentUser;
    // const userid = user.uid
    // const photo = user.photoURL

    if (!user) {
      setShowSignInButton(true); // Show the sign-in modal if user is not signed in
      return;
    }

    // console.log(subIndex, is null)
    // console.log(showupload)
    setIsLoading(true);
    // var tagExist = checkIfTagsExist();
    // console.log('subIndex: ', subIndex)
    // console.log('subIndexG: ', subIndexG)
    if(subIndexG == null){
      // get tag
      const openai = new OpenAI({apiKey:'sk-proj-5Xc3ZBosQlmDs8LndaxtT3BlbkFJiDpsq70OVKc3A7rkkEYr', dangerouslyAllowBrowser: true});

      try {
        // Prepare the prompt for OpenAI Completions API
        const prompt = `
          Please choose the most relevant tags from the following list for the given input text:
              Tags: ${tags.join(', ')}
              Input text: "${text}"
              Respond with the relevant tags, separated by commas.
              If you can't find related tags use 'success'.
        `;

        // Call OpenAI API to get relevant tags
        const response = await openai.chat.completions.create({
          // 'https://api.openai.com/v1/completions',
          messages:[{
            "role": "user",
            "content": prompt,
        }],
          model:"gpt-4o-mini",
        });

        console.log('mini:', response.choices[0].message.content)

        // Parse the response to extract the tags
        const tagsFromAPI = response.choices[0].message.content
          .trim()
          .split(',')
          .map(tag => tag.trim());

        // Set the selected tags in the state
        setSelectedTags(tagsFromAPI);
        setSubTags(tagsFromAPI)
        // console.log('tagsFromAPI: ', tagsFromAPI)
        setTriggerTags(true)
        // Once tags are selected, call the convertTextToAudio function
        // convertTextToAudio(searchText, subfreq);
        setIsLoading(false);


      } catch (error) {
        console.error('Error fetching tags from OpenAI:', error);
        setIsLoading(false);
      }
      return
    }
    
    try {
      
      const user = auth.currentUser;
      const userid = user.uid
      // console.log('userid: ', userid)
      // Get reference to the user's document in the 'users' collection
      const userDocRef = doc(db, 'users', userid);
  
      // Get the document snapshot
      const userDocSnap = await getDoc(userDocRef);
  
      // Check if the document exists
      if (userDocSnap.exists()) {
        // Get the 'subliminals' field data
        const subliminalsData = userDocSnap.data().subliminals;
        // console.log(subliminalsData)
  
        // Check if 'tags' field exists in the 'subliminals' object
        if (subliminalsData && subliminalsData[subIndexG].hasOwnProperty('tags')) {
          // console.log('Tags field exists:', subliminalsData[subIndexG].tags);
          // console.log('sub:', subliminalsData[subIndexG].mp3length);

          // return true; // Field exists
          //**** */undo
          // convertTextToAudio(searchText, subfreq);
          setTriggerTags(true)
          setIsLoading(false);


        } else {
          // console.log('Tags field does not exist.');
          // return false; // Field does not exist
          // console.log('e wan create')
          // get tag
          const openai = new OpenAI({apiKey:'sk-proj-5Xc3ZBosQlmDs8LndaxtT3BlbkFJiDpsq70OVKc3A7rkkEYr', dangerouslyAllowBrowser: true});

          try {
            // Prepare the prompt for OpenAI Completions API
            const prompt = `
              Please choose the most relevant tags from the following list for the given input text:
              Tags: ${tags.join(', ')}
              Input text: "${text}"
              Respond with the relevant tags, separated by commas.
            `;

            // Call OpenAI API to get relevant tags
            const response = await openai.chat.completions.create({
              // 'https://api.openai.com/v1/completions',
              messages:[{
                "role": "user",
                "content": prompt,
            }],
              model:"gpt-4o-mini",
            });

            console.log('mini:', response.choices[0].message.content)

            // Parse the response to extract the tags
            const tagsFromAPI = response.choices[0].message.content
              .trim()
              .split(',')
              .map(tag => tag.trim());

            // Set the selected tags in the state
            // setSelectedTags(tagsFromAPI);
            // console.log('tagsFromAPI: ', tagsFromAPI)

            // Update the subliminals array with the new tags
            const updatedSubliminals = [...subliminalsData];
            updatedSubliminals[subIndexG] = {
              ...updatedSubliminals[subIndexG],
              tags: tagsFromAPI
            };

            // Save the updated subliminals array back to Firestore
            await updateDoc(userDocRef, {
              subliminals: updatedSubliminals
            });

            // Once tags are selected, call the convertTextToAudio function
            // convertTextToAudio(searchText, subfreq);
            setSubTags(tagsFromAPI)
            setTriggerTags(true)
            setIsLoading(false);

          } catch (error) {
            console.error('Error fetching tags from OpenAI:', error);
            setIsLoading(false);

          } finally {
            setIsLoading(false);
            setTriggerTags(true)

           
          }

        }
      } else {
        console.log('Document does not exist.');
        setIsLoading(false);
        return false; // Document does not exist
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      setIsLoading(false);
      return false; // Handle error
    }


    // console.log('tagExist: ', tagExist)
    // if (tagExist){
    //   console.log('e already de')
    //   // If tags exists, call the convertTextToAudio function
    //   convertTextToAudio(searchText, subfreq);
    // }else{
    //     console.log('e wan create')
    //     // get tag
    //     const openai = new OpenAI({apiKey:'sk-proj-5Xc3ZBosQlmDs8LndaxtT3BlbkFJiDpsq70OVKc3A7rkkEYr', dangerouslyAllowBrowser: true});

    //     try {
    //       // Prepare the prompt for OpenAI Completions API
    //       const prompt = `
    //         Please choose the most relevant tags from the following list for the given input text:
    //         Tags: ${tags.join(', ')}
    //         Input text: "${searchText}"
    //         Respond with the relevant tags, separated by commas.
    //       `;

    //       // Call OpenAI API to get relevant tags
    //       const response = await openai.chat.completions.create({
    //         // 'https://api.openai.com/v1/completions',
    //         messages:[{
    //           "role": "user",
    //           "content": prompt,
    //       }],
    //         model:"gpt-4o-mini",
    //       });

    //       console.log('mini:', response.choices[0].message.content)

    //       // Parse the response to extract the tags
    //       const tagsFromAPI = response.choices[0].message.content
    //         .trim()
    //         .split(',')
    //         .map(tag => tag.trim());

    //       // Set the selected tags in the state
    //       setSelectedTags(tagsFromAPI);
    //       console.log('tagsFromAPI: ', tagsFromAPI)

    //       // Once tags are selected, call the convertTextToAudio function
    //       convertTextToAudio(searchText, subfreq);

    //     } catch (error) {
    //       console.error('Error fetching tags from OpenAI:', error);
    //     } finally {
    //       // setIsLoading(true);
    //     }

    // }
    
  };

 

  const convertTextToAudio = async (text, subfreq) => {
    if(error1 !== ''){
      return;
    }
    mixpanel.track('convertTextToAudio', {
      frequency: subfreq,
      sound: selectedBackground,

    })
    console.log('text: ', text)
    setIsLoading(true);
    const user = auth.currentUser;
    if(user){

    try {
      
      setAudioUrls([]);
      // setIsLoading(true); // Start loading spinner
      setAudioUrls([]); // Clear previous audio URLs

      // if (Array.isArray(text)) {
      //   // Join all the elements in the array into a single string
      //   text = text.join(' ');
      //   // console.log(combinedText);
      // }

      const randomIndex = Math.floor(Math.random() * voices.length);
      const selectedVoice = voices[randomIndex];
  
      // Split the text into smaller chunks (e.g., 500 characters per chunk)
      const chunkSize = 500;
      const chunks = text.match(new RegExp(`.{1,${chunkSize}}`, 'g'));
  
     

        const response = await axios.post(
          // const voices = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer']
          'https://api.openai.com/v1/audio/speech',
          {
            model: "tts-1-hd",
            input: text,
            voice: 'nova'
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer sk-proj-qtV3m5XrhzKtwoTyBOKUT3BlbkFJWg5KX1pdLEzC4EBzn5HC',
            },
            responseType: 'arraybuffer' // Specify response type as blob
          }
        );
  
        // const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
        // audioUrl = URL.createObjectURL(audioBlob);
        // console.log('audioBlob: ', audioBlob)
        // // setAudioUrls([audioUrl])
        // console.log('audioUrl: ', audioUrl)
        // playAudio(audioUrl); // Play the audio

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const audioBuffer = await audioContext.decodeAudioData(response.data);
        // console.log('audioBuffer:', audioBuffer);
        // Create a Blob URL from the filtered audio buffer
        audioBlobUrl = await convertAudioBufferToBlobUrl(audioBuffer, subfreq);
        setAudioUrls([audioBlobUrl]);
        // console.log('audioBlobUrl:', audioBlobUrl);

        // Store the references for dynamic frequency change
        audioContextRef.current = audioContext;
        const gainNode = audioContext.createGain();
        const biquadFilter = audioContext.createBiquadFilter();
        biquadFilter.type = 'highpass';
        biquadFilter.frequency.value = subfreq;
        biquadFilter.Q.value = 1;

        gainNode.connect(audioContext.destination);
        biquadFilter.connect(gainNode);

        gainNodeRef.current = gainNode;
        biquadFilterRef.current = biquadFilter;
        setTriggerTags(true);
        setError('')
            //     // Function to play audio URLs sequentially
      //   const playAudioSequentially = async (url, subfreq, playbackRate = 0.90) => {
      //       audioContext = new (window.AudioContext || window.webkitAudioContext)();
      //       gainNode = audioContext.createGain();
      //       const biquadFilter = audioContext.createBiquadFilter();
      //       biquadFilter.type = 'highpass';
      //       biquadFilter.frequency.value = subfreq;
      //       biquadFilter.Q.value = 1; // Quality factor, adjust for bandwidth


      //       gainNode.connect(audioContext.destination);
      //       biquadFilter.connect(gainNode);

      //       // for (let url of urls) {
      //         const response = await fetch(url);
      //         const audioData = await response.arrayBuffer();
      //         const audioBuffer = await audioContext.decodeAudioData(audioData);
      //         // Convert audioBuffer to Blob URL and update state
      //         const audioBlobUrl = await convertAudioBufferToBlobUrl(audioBuffer);
      //         setAudioUrls([audioBlobUrl]);
      //         console.log('audioBlobUrl: ', audioBlobUrl)
      //         // source = audioContext.createBufferSource();
      //         // source.buffer = audioBuffer;
      //         // source.playbackRate.value = playbackRate;
      //         // source.connect(biquadFilter);
      //         // source.start(0);
              
      //         // await new Promise((resolve) => {
      //         //   source.onended = resolve;
      //         //   console.log('source: ', source)
             
      //         // });
      //       // }
      // };

      // Play the audio URLs
      // await playAudioSequentially(audioUrl, subfreq);
      
    } catch (error) {
      console.error('Error converting text to audio:', error.message);
      setError('Error converting text to audio. Please try again.');

    } 
    finally {
      setIsLoading(false); // Stop loading spinner
      setError2('');
      setError3('')

    }
  }else{
    setIsLoading(false);
    setShowSignInButton(true); // Show the sign-in modal if user is not signed in
    return;
  }
  };

  // const playAudioSequentially = async (url, subfreq, playbackRate = 0.90) => {
  //   const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  //   const gainNode = audioContext.createGain();
  //   const biquadFilter = audioContext.createBiquadFilter();
  //   biquadFilter.type = 'highpass';
  //   biquadFilter.frequency.value = subfreq;
  //   biquadFilter.Q.value = 1; // Quality factor, adjust for bandwidth
  
  //   gainNode.connect(audioContext.destination);
  //   biquadFilter.connect(gainNode);
  
  //   const response = await fetch(url);
  //   const audioData = await response.arrayBuffer();
  //   const audioBuffer = await audioContext.decodeAudioData(audioData);
  
  //   // Create a Blob URL from the filtered audio buffer
  //   const audioBlobUrl = await convertAudioBufferToBlobUrl(audioBuffer);
  //   setAudioUrls([audioBlobUrl]);
  //   console.log('audioBlobUrl:', audioBlobUrl);
  
  //   const source = audioContext.createBufferSource();
  //   source.buffer = audioBuffer;
  //   source.playbackRate.value = playbackRate;
  //   source.connect(biquadFilter);
  //   source.start(0);
  
  //   source.onended = () => {
  //     console.log('Audio playback finished');
  //   };
  // };

  const convertAudioBufferToBlobUrl = async (audioBuffer, subfreq) => {
    const offlineContext = new OfflineAudioContext(
      audioBuffer.numberOfChannels,
      audioBuffer.length,
      audioBuffer.sampleRate
    );
  
    const bufferSource = offlineContext.createBufferSource();
    bufferSource.buffer = audioBuffer;
  
    // Apply the highpass filter
    const biquadFilter = offlineContext.createBiquadFilter();
    biquadFilter.type = 'highpass';
    biquadFilter.frequency.value = subfreq;
    biquadFilter.Q.value = 1; // Quality factor, adjust for bandwidth
  
    bufferSource.connect(biquadFilter);
    biquadFilter.connect(offlineContext.destination);

    bufferSource.start();
  
    const renderedBuffer = await offlineContext.startRendering();
    // const wavBlob = new Blob([new DataView(renderedBuffer.getChannelData(0).buffer)], { type: 'audio/wav' });
    const wavBlob = bufferToWaveBlob(renderedBuffer);
    return URL.createObjectURL(wavBlob);
  };

  const bufferToWaveBlob = (buffer) => {
    const numOfChannels = buffer.numberOfChannels;
    const length = buffer.length * numOfChannels * 2 + 44;
    const arrayBuffer = new ArrayBuffer(length);
    const view = new DataView(arrayBuffer);
  
    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };
  
    let offset = 0;
  
    writeString(view, offset, 'RIFF'); offset += 4;
    view.setUint32(offset, 36 + buffer.length * 2, true); offset += 4;
    writeString(view, offset, 'WAVE'); offset += 4;
    writeString(view, offset, 'fmt '); offset += 4;
    view.setUint32(offset, 16, true); offset += 4;
    view.setUint16(offset, 1, true); offset += 2;
    view.setUint16(offset, numOfChannels, true); offset += 2;
    view.setUint32(offset, buffer.sampleRate, true); offset += 4;
    view.setUint32(offset, buffer.sampleRate * 2, true); offset += 4;
    view.setUint16(offset, numOfChannels * 2, true); offset += 2;
    view.setUint16(offset, 16, true); offset += 2;
    writeString(view, offset, 'data'); offset += 4;
    view.setUint32(offset, buffer.length * 2, true); offset += 4;
  
    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numOfChannels; channel++) {
        const sample = Math.max(-1, Math.min(1, buffer.getChannelData(channel)[i]));
        view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
        offset += 2;
      }
    }
  
    return new Blob([view], { type: 'audio/wav' });
  };

  const playAudio = (audioUrl) => {
      const audio = new Audio(audioUrl);
      audio.oncanplay = () => {
        audio.play();
      };
    };

  
  // const handlePlayClick = () => {
  //   if (audioUrl) {
  //     const audio = new Audio(audioUrl);
  //     audio.play();
  //   }
  // };



  function getData(val)
   {
     setData(val.target.value)
    //  handleSearchTextChange()
   }

//      // Function to handle audio replay
//   const handleAudioReplay = () => {
//     // Play the audio only if replay count is less than 5
//     if (replayCount < 5) {
//       // Increment the replay count
//       setReplayCount(prevCount => prevCount + 1);
//       // Play the audio
//       if (audioRef.current) {
//         audioRef.current.play();
//       }
//     }
// };


// // Reset replay count when conversation changes
// useEffect(() => {
//   setReplayCount(0);
// }, [conversation]);




   const searchGreat = (e) => {
   
    if (e) {
    e.preventDefault();
  }

    if (new_data == ""){
        alert('Kindly write a search word')
        // setData('Happy')
        mixpanel.track('searchGreatWithoutData', {
          data: 'null'
        })
        return;
      }

      mixpanel.track('searchGreat', {
        data: new_data
      })
        
        data_low = new_data.toLowerCase();
        data_up = new_data.charAt(0).toUpperCase() + new_data.slice(1);

        // console.log(new_data)
        // console.log(data_up)
        // console.log(data_low)

        if(data_up == null){
          setShowIfEmpty(false)
        }else{
          setShowIfEmpty(true)
        }
        // var data_low_rep = data_low.replace(' ', ',')
        const data_lowArray = data_low.split(' ');
        data_lowArray.push(data_low)
        // Query for 'name' field
        const queryByName = db.collection("greats")
        .where('name', 'array-contains-any', [data_up]);

        // Query for 'energy' field
        const queryByEnergy = db.collection("greats")
        .where('energy', 'array-contains-any', [data_up]);

        // Perform both queries concurrently using Promise.all()
        Promise.all([queryByName.get(), queryByEnergy.get()])
        .then(([nameSnapshot, energySnapshot]) => {
          // Combine the results from both queries
          const nameResult = nameSnapshot.docs.map(doc => ({
            docid: doc.id,
            ...doc.data()
          }));
          const energyResult = energySnapshot.docs.map(doc => ({
            docid: doc.id,
            ...doc.data()
          }));

          // Merge the results and remove duplicates
          const mergedResult = [...nameResult, ...energyResult];
          const uniqueResult = mergedResult.filter((item, index, self) =>
            index === self.findIndex(t => (
              t.docid === item.docid && t.name === item.name
            ))
          );

          // Set the cards state with the merged and unique results
          setCards(uniqueResult);
          setShowTraits(false);

          // Check if the result is empty
          if (uniqueResult.length === 0) {
            setShowIfEmpty(false);
            // handleShowUpload()
            alert(`Sorry, we haven't got this Energy yet. Try a closer Energy.`);
            setShowTraits(true);
          }
        })
        .catch((error) => {
          // console.error('Error searching for energy:', error);
        });

   }

   const getRandomBorderColor = () => {
    const colors = ['red', 'blue', 'green', 'orange', 'purple', 'pink'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

   const handleCreate=()=>{

   }

   const handleStartStop = () => {
    mixpanel.track('handleStartStop', {
      frequency: frequency
    })

    const user = auth.currentUser;

  if (user) {
    userid = user.uid;

 

    if (isRestart) {
      setFrequency(0); // Reset frequency to 1
      setCountdownTime(freqTop); // Reset countdown time
      setIsRestart(false); // Reset restart state
      setIsCountingDown(true); // Start countdown
      setIsIncreasing(true); // Start increasing frequency
    } else {
    setIsCountingDown(!isCountingDown);

  //   if (frequency==1){
  //     setAudioUrls([]);
  //   // convertTextToAudio("Follow this imagination exercise. It may seem repetitive or impossible, but this is intentional. . . Picture your brain radiating energy and whenever you hear a number, the energy radiating from your brain increases by that number.");
  //   // convertTextToAudio("Follow this imagination exercise. It may seem repetitive or impossible, but this is intentional. . . Picture your heart energy expanding from the center of your chest outward outside your body and whenever you hear a number, your heart energy increases by that number.");  
  //   // convertTextToAudio("This exercise is based on Quantum Jumping. It may seem repetitive, but that's intentional. . . Whenever you hear a question, notice the subtle change in your energy. .");  
  //   // You are ' + comp + ' than ' + article  + fullname + '. How ?'
  //   // convertTextToAudio('Visualize an energy ' + comp + ' than ' + article  + fullname + '.');
  //   // convertTextToAudio("This exercise is based on connects you to your parallel lifes. Notice the subtle change in your energies, Whenever you hear the prompt. .");  
  //   convertTextToAudio("The Social Energy Contract is an ancient unspoken agreement where we are forced to release our emotions or energies, according to the time and type of outside stimulus. For example, being praised by a random stranger,  or being told off by our partner. In this exercise, we will release positive energies without waiting for outside permission or stimulus.")
  //   // convertTextToAudio('Visualize a cup of water charged with the ' + {energy} + ' energy of ' + article  + fullname + '.');
  //   setAudioUrls([]);
  // }
    setIsIncreasing(!isIncreasing);
    }

  }else{
    setShowSignInButton(true); // Show the sign-in modal if user is not signed in
    return;
  }
  };

  const lowercaseFirstLetter = (string) => {
    return string.charAt(0).toLowerCase() + string.slice(1);
  };



  const handleBack = () => {
    mixpanel.track('backButton', {
      message: "backButton clicked"
    })
    
    setShowNewContent(false);
  };

    
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  function isVowel(char) {
    return ['a', 'e', 'i', 'o', 'u'].includes(char.toLowerCase());
  }

  const irregularAdjectivesComparative = {
    'good': 'better',
    'bad': 'worse',
    'far': 'farther',
    'little': 'less',
    'much': 'more',
    'many': 'more',
    'strong': 'stronger',
    'sweet':'sweeter',
    'delicious':'more delicious',
    'masculine': 'more masculine',
    'charitable': 'more charitable',
    'old': 'older' // 'elder' is another comparative for 'old' in the sense of seniority within a family.
  };

  function convertToComparative(adjective) {
    const length = adjective.length;

    if (irregularAdjectivesComparative[adjective]) {
      ene_comp = irregularAdjectivesComparative[adjective];
    }
    
    
    // Handle adjectives ending in 'e'
    if (adjective.endsWith('e')) {
      ene_comp = adjective + 'r';
    }

    // Handle adjectives ending in 'e'
    if (adjective.endsWith('d')) {
      ene_comp = 'more ' + adjective;
    }

     // Handle adjectives ending in 'e'
     if (adjective.endsWith('l')) {
      ene_comp = 'more ' + adjective;
    }

    // Handle adjectives ending in 'e'
    if (adjective.endsWith('s')) {
      ene_comp = 'more ' + adjective;
    }
  
    // Handle adjectives ending in 'y'
    if (adjective.endsWith('y') && !isVowel(adjective[length - 2])) {
      ene_comp = adjective.slice(0, -1) + 'ier';
    }
    
    // Handle short adjectives (typically one syllable)
    if (length <= 4 || (length === 5 && isVowel(adjective[0]) && !isVowel(adjective[1]))) {
      ene_comp = adjective + 'er';
    }

    if (length > 6 ) {
      ene_comp = 'more ' + adjective;
    }

    // Handle long adjectives (typically two or more syllables)
    // ene_comp = 'more ' + adjective;
    setComp(ene_comp)
  }

  const irregularAdjectives = {
    'good': 'best',
    'bad': 'worst',
    'far': 'farthest',
    'little': 'least',
    'much': 'most',
    'many': 'most',
    'strong': 'strongest',
    'old': 'oldest',
    'delicious':'most delicious',
    'masculine': 'most masculine',
    'charitable': 'most charitable',
    'old': 'oldest',
    'sweet':'sweetest' // In the sense of age, 'eldest' is another superlative for 'old' in the sense of seniority within a family.
  };
  
  function isVowel(char) {
    return ['a', 'e', 'i', 'o', 'u'].includes(char.toLowerCase());
  }
  
  function convertToSuperlative(adjective) {
    const length = adjective.length;

    if (irregularAdjectives[adjective]) {
     ene_sup = irregularAdjectives[adjective];
    }
    
    
    // Handle adjectives ending in 'e'
    if (adjective.endsWith('e')) {
      ene_sup = adjective + 'st';
    }

    // Handle adjectives ending in 'e'
    if (adjective.endsWith('l')) {
      ene_sup = 'most ' + adjective;
    }

    // Handle adjectives ending in 'e'
    if (adjective.endsWith('d')) {
      ene_sup = 'most ' + adjective;
    }

    // Handle adjectives ending in 'e'
    if (adjective.endsWith('s')) {
      ene_sup = 'most ' + adjective;
    }
  
    // Handle adjectives ending in 'y'
    if (adjective.endsWith('y') && !isVowel(adjective[length - 2])) {
      ene_sup = adjective.slice(0, -1) + 'iest';
    }
    
    // Handle short adjectives (typically one syllable)
    if (length <= 4 || (length === 5 && isVowel(adjective[0]) && !isVowel(adjective[1]))) {
      ene_sup = adjective + 'est';
    }

    if (length > 6 ) {
      ene_sup = 'most' + adjective;
    }
    
    // Handle long adjectives (typically two or more syllables)
    // ene_sup = 'most ' + adjective;
    setSup(ene_sup)
  }
  
  const getArticle = (word) => {
    // List of uncountable nouns that don't use 'a' or 'an'
  const uncountableNouns = ['water', 'sand', 'rice', 'music', 'food', 'fire', 'wind', 'night'];
    // Check if the word starts with a vowel sound
    const vowels = ['a', 'e', 'i', 'o', 'u'];
    const firstLetter = word[0].toLowerCase();

    if(isHuman){
      setArticle(' ')
      return;
    }

    const uncountableThe = ['sea', 'river', 'wind', 'sky'];
    if (uncountableThe.includes(String(word).toLowerCase())) {
      setArticle('the')
      return;
    }


    // Check if the word is uncountable
  if (uncountableNouns.includes(String(word).toLowerCase())) {
    setArticle(' ')
    return;
  }
  
    if (vowels.includes(firstLetter)) {
      setArticle('an')
    } else {
      setArticle('a');
    }
  };

  const handleInspirationChange = (event) => {
    const inspirationText = event.target.value
    setInspirationText(inspirationText)
  }

  const handleSearchTextChange = (event) => {
    setShowSave(true)
    const value = event.target.value;
      if (value.length > 1111) {
        setError1(`Character limit exceeded. Maximum allowed is 1111 characters.`);
      } else {
        setError1('');
        setSearchText(value);
      }
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    setData(newSearchText)
    // console.log('searchText:', data)

    if(searchText.length == 0 ){
      setFilteredHints([])
      return;
    }

    // if(searchText.length == -1 ){
    //   setFilteredHints([])
    //   return;
    // }

    if(searchText.length < 1){
      setFilteredHints([])
      return;
    } else {
     // Filter hints based on search text
    const uniqueEnergiesSet = new Set();
    // console.log("hints: ", hints)
    const filteredHints = hints.filter((energy) => {
      const matches = energy.toLowerCase().startsWith(newSearchText.toLowerCase());
      if (matches && !uniqueEnergiesSet.has(energy.toLowerCase())) {
        uniqueEnergiesSet.add(energy.toLowerCase());
        return true;
      }
      return false;
    });

    setFilteredHints(filteredHints);
    // console.log('filteredHints:', filteredHints);
}

  };

  function getSelectedHint(hintOption)
    {
      // console.log('hintOption: ', hintOption.target.outerText)
      // setSelectedHint(hintOption.target.outerText)
      setSearchText(hintOption.target.outerText)
      setFilteredHints([])
      // searchHints()
    }

  const searchGreatHint=(text)=>{
    // if (hint !== null){
      new_data = text
      energy = text
      setData(text)
      setSearchText(text)
      // searchGreat()
    // }
    searchGreat()
    // console.log(new_data)
    // console.log(text)
  }

  const signInWithGoogle = async () => {
    mixpanel.track('signInWithGoogleForInsight', {
      message: "signInWithGoogleForInsight"
    });

    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      setShowSignInButton(false); // Close the sign-in modal after successful sign-in
    } catch (error) {
      // console.error("Error during sign-in:", error);
    }
  };

  const playInfo = () => {
    mixpanel.track('PlayIntro', {
      // user: userEmail,
      // energy: ene
    })
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch(error => {
          // console.error("Error playing the audio:", error);
        });
      }
      setIsPlaying(!isPlaying);
    }
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
    {isHuman ? (
      "Humans"
    ):(
      "Nature"
    )}
    </Tooltip>
  );
  
  

  // Filter cards based on the isHuman state
  const filteredCards = isHuman
    ? cards.filter(card => !card.s)
    : cards.filter(card => card.s);

   const borderColors = ['red', 'blue', 'green', 'orange', 'purple', 'teal'];
   

   const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).format(date);
    // const formattedTime = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' }).format(new Date(`1970-01-01T${timeString}`));
    return `(${formattedDate})`;
  };

  const renderTooltipAlive = (props) => (
    <Tooltip id="button-tooltip" {...props}>
    <img
                        src="./wheel.gif"
                        width="25"
                        height="25"
                        alt="Living Subliminal"
                      />
       SubWheels are inspired by Buddhist 'Prayer Wheels'. To activate, click Activate. They play 24/7 online (like a radio) even when you're not listening. All you need to do is remember that they're playing and working for you.
    </Tooltip>
  );


  const handleLoadedMetadata = (event) => {
    const audioElement = event.target;
    if (audioElement.duration > 0){
    setDuration(audioElement.duration)
  }
    // audioElement.currentTime = tostart;

    if (!isNaN(tostart) && tostart >= 0) { // Check if tostart is a valid number
      audioElement.currentTime = tostart;
    } else {
      // console.log('Invalid tostart value:', tostart);
    }

  };

  const deleteSub = async (index) => {
      const me = auth.currentUser.uid;
      if (typeof me !== 'string') {
        // console.error('Invalid userID, expected a string.');
        return;
      }
    const userDocRef = doc(firestore, 'users', me);
  
    try {
      const docSnap = await getDoc(userDocRef);
  
      if (docSnap.exists()) {
        const data = docSnap.data();
        const subliminals = data.subliminals;
  
        if (subliminals && Array.isArray(subliminals) && index >= 0 && index < subliminals.length) {
          subliminals.splice(index, 1); // Remove the element at the specified index

          await updateDoc(userDocRef, { subliminals });
  
          // console.log(`Deleted index ${index} from subliminals array`);
        } else {
          // console.error('Index out of bounds or subliminals array does not exist');
        }
      } else {
        // console.error('Document does not exist');
      }
    } catch (error) {
      // console.error('Error deleting sub:', error);
    }
  };

  const handleDelete = (index) => {
    deleteSub(index);
    handleCloseMySub()
    // handleShowMySub()
  };

  const getNextTestimony = () => {
    // Ensure there are testimonies to cycle through
    if (testimonies.length > 0) {
      // Update the state with the next testimony
      setCurrentResultIndex((prevIndex) => (prevIndex + 1) % testimonies.length);
    }
  };

  const getTestimony = async () => {
    mixpanel.track('getTestimony', {});
    

    // TODO future: getTestimony triggers unnessrily when generate cards are clicked

    // const user = auth.currentUser;
    // console.log('subTags', subTags)
    // if (user) {
      // const userid = user.uid;

      try {
        // Query the 'results' collection where 'tags' array contains 'beauty'
        const queryByTag = db.collection("results").where('tags', 'array-contains-any', subTags);

        // Execute the query and await the results
        const querySnapshot = await queryByTag.get();

        if (!querySnapshot.empty) {
          // Map through the documents and extract required fields
          const fetchedTestimonies = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            const docid = doc.id; // Get the document ID
            const { by, on, testimony, principles, mp3 } = data;
            return { by, on, testimony, principles, mp3, docid };
          });

          // console.log(fetchedTestimonies)

          // compare played array with fetchedTestimonies
          // if there's anyone that hasn't been listened to 
          // set notification to true
          const user = auth.currentUser

          if (user) {
            const userid = user.uid;
            // console.log('userid: ', userid)
        
        try {
              // 1. Get the user's 'played' array from the 'users' collection
              const userRef = db.collection("users").doc(userid);
              const userDoc = await userRef.get();
              let playedArray = [];
              
              if (userDoc.exists) {
                playedArray = userDoc.data().played || []; // Get 'played' array or empty array if it doesn't exist
                // console.log('playedArray: ', playedArray)
              }

              // 4. Compare playedArray with fetchedTestimonies to find those not in playedArray
              const unplayedTestimonies = fetchedTestimonies.filter(testimony => !playedArray.includes(testimony.docid));
              setUnplayed(unplayedTestimonies)
              // 5. Set the number of unplayed testimonies to notification
              const unplayedCount = unplayedTestimonies.length;
              // console.log('unplayedCount: ', unplayedCount)
              setNotifications(unplayedCount); // Assuming you have a setNotifications function to set the count
            
            } catch (error) {
              console.error('Error fetching documents:', error);
            }
          }
        

          // Set testimonies in state
          setTestimonies(fetchedTestimonies);

        } else {
          // No matching documents found
          setTestimonies([]);
          setNotifications(0); // No testimonies, so no notifications
        }
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    // } else {
    //   console.log('User not signed in');
    // }
  };

  // const getPopulation = async () => {
  //   mixpanel.track('getTestimony', {});


  //   // const user = auth.currentUser;
  //   console.log('population', subPopulation)
  //   // if (user) {
  //     // const userid = user.uid;

  //     try {
  //       // Query the 'results' collection where 'tags' array contains 'beauty'
  //       const queryByTag = db.collection("results").where('tags', 'array-contains-any', subTags);

  //       // Execute the query and await the results
  //       const querySnapshot = await queryByTag.get();

  //       if (!querySnapshot.empty) {
  //         // Map through the documents and extract required fields
  //         const fetchedTestimonies = querySnapshot.docs.map((doc) => {
  //           const data = doc.data();
  //           const { by, on, testimony, principles, mp3 } = data;
  //           return { by, on, testimony, principles, mp3 };
  //         });

  //         // Set testimonies in state
  //         setTestimonies(fetchedTestimonies);

  //       } else {
  //         // No matching documents found
  //         setTestimonies([]);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching documents:', error);
  //     }

  // };

  // Fetch testimonies when component mounts
  useEffect(() => {
    if(triggerTags){
    getTestimony();
    console.log('testimony effect')
    setTriggerTags(false)
  }

  }, [triggerTags]);

  // useEffect(() => {
  //   if(triggerPopulation){
  //   getTestimony();
  //   console.log('population effect')
  //   setTriggerPopulation(false)
  // }

  // }, [triggerPopulation]);

  const handleLivingCheckBox = async (event) => {
    const isChecked = event.target.checked;
    const user = auth.currentUser;
    mixpanel.track('Activate Subwheel?', {
      activate: isChecked,
    })
    if (user) {
    
      const userid = user.uid;
      const userDocRef = doc(firestore, 'users', userid);

      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const subliminals = userDoc.data().subliminals;

          if (subIndexG !== null && subliminals && subliminals.length > subIndexG) {
            const updatedSubliminals = [...subliminals];
            updatedSubliminals[subIndexG].livingSub = isChecked;

            const subData = updatedSubliminals[subIndexG];
            var { mp3length, lastClosed} = subData;
            console.log("mp3length: ", mp3length)
            if(mp3length == undefined){
              setError3("Error: It's weird but click 'Play' then close it, before reactivating Subwheel...")
              return
            }
            // if(mp3length == 0){
            //   setError3("Error: It's weird but click 'Play' then close it, before reactivating Subwheel...")
            //   return
            // }
            // if(lastClosed == 0){
            //   setError3("Error: It's weird but click 'Play' then close it, before reactivating Subwheel...")
            //   return
            // }
           

          
              await updateDoc(userDocRef, {
                subliminals: updatedSubliminals
              });
              setLlivingSub(isChecked)

            
            // console.log(`Checkbox is ${isChecked ? 'checked' : 'unchecked'}`);
          } else {
            // console.error('Invalid subliminal index');
            setError3("This Sub hasn't been created yet. Please click 'Play', then Save the played sub before activating Subwheel...")
            return
          }
        }
      } catch (error) {
        // console.error('Error updating document: ', error);
      }
    } else {
      // Give sign-in nudge
      setShowSignInButton(true); // Show the sign-in modal if user is not signed in
      return;
      // console.log('User not signed in');
    }
  };

  const handleShowMore = () => {
    setVisibleAffirmations((prevVisible) => prevVisible + 5);
    // handleShowPay()
  };

  const handleShowLess = () => {
    setVisibleAffirmations(5);
  };

  
  const playAnalysis = (mp3) => {
    mixpanel.track('Analysis played', {});
    console.log('mp3: ', mp3);
  
    // Create a new Audio object with the mp3 URL and play it
    const audio = new Audio(mp3);
    audio.play()
      .then(() => {
        console.log('Audio is playing');
      })
      .catch((error) => {
        console.error('Error playing audio:', error);
      });
  };



  const [isPlaying2, setIsPlaying2] = useState(false);
  const [audio, setAudio] = useState(null);
  const audioRef2 = useRef([]);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [newNotes, setNewNotes] = useState([]);

  const toggleAudio = async (mp3, by, doc, index) => {

    mixpanel.track('Play Result?', {
      by: by,
    })

    // TODO: if doc is in unplayed then the color should be black else ash

  //  update played list
    const user = auth.currentUser;
    const useridd = user.uid;
    const userRef = db.collection('users').doc(useridd);
    const res = await userRef.update({
      played: firebase.firestore.FieldValue.arrayUnion(doc)
    });

    if (notifications > 0 && !newNotes.includes(doc)){
      setNewNotes((prevItems) => [...prevItems, doc]);
      const newnote = notifications - 1
      setNotifications(newnote)
  }


    if (!audioRef2.current[index]) {
      audioRef2.current[index] = new Audio(mp3);
    }

    const currentAudio = audioRef2.current[index];

    // console.log('result mp3: ', mp3, by)

    if (playingIndex === index) {
      currentAudio.pause();
      // If the currently playing button is clicked again, pause it
      setPlayingIndex(null);
      
    } else {
        // Pause any currently playing audio
        if (playingIndex !== null) {
          audioRef2.current[playingIndex]?.pause();
        }

        // Play the selected audio
      currentAudio.play()
      .then(() => {
        setPlayingIndex(index);
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Play request was interrupted by pause');
        } else {
          console.error('Error playing audio:', error);
        }
      });

        // Otherwise, set the clicked button's index as the playing one
  
    }
    
  };

  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  function numberToWords(num) {
    const g = [
        '', 'Thousand', 'Million', 'Billion', 'Trillion', 'Quadrillion', 'Quintillion'
    ];

    // Splits the number into groups of three digits starting from the right
    function chunkNumber(numStr) {
        let chunks = [];
        while (numStr.length > 0) {
            chunks.push(numStr.slice(-3));
            numStr = numStr.slice(0, -3);
        }
        return chunks;
    }

    // Handles numbers like 1,000,000 or 1,000,000,000, etc.
    function specialCases(num) {
        const numStr = num.toString();
        if (numStr.match(/^1(0)+$/)) {  // Matches numbers like 1000, 1000000, 1000000000, etc.
            const power = numStr.length - 1;
            const suffixIndex = Math.floor(power / 3);  // Get the index for thousand, million, etc.
            return `1 ${g[suffixIndex]}`;
        }
        return null;
    }

    // Convert numbers normally using digits for the number part and capitalize the suffix
    function convertWithDigit(num) {
        let numStr = num.toString();
        let chunks = chunkNumber(numStr);

        let wordArray = chunks.map((chunk, index) => {
            let chunkInt = parseInt(chunk);

            // Skip empty groups (i.e., for numbers like 1,000,000,000 where some groups are 000)
            if (chunkInt === 0) return '';

            // Return digit + capitalized suffix (e.g., 2 Million)
            return `${chunkInt} ${g[index]}`;
        });

        return wordArray
            .filter(Boolean) // Remove empty parts
            .reverse() // Correct the order of chunks
            .join(' ') // Join the result with spaces
            .trim();
    }

    return specialCases(num) || convertWithDigit(num); // Use special case if applicable
}





  
  return (
    <div>
      <Form onSubmit={searchGreat}>
        <Form.Group className="mb-3" controlId="formBasicEmail" style={{ margin: 5, marginTop: 0 }}>
          <div style={{ textAlign: "center" }}>
            <small className="text-muted">
              <h1>
                {/* <b style={{ color: "#070707", fontSize:35, fontStyle: "normal", fontFamily:"Seymour One", fontWeight:400}}><b>Become</b> </b> */}
                {/*<b style={{ color: "#070707", fontSize:35, fontStyle: "normal", fontFamily:"Seymour One", fontWeight:400}}>Tap  </b>*/}
                {/* <b style={{ color: "#070707", fontSize:'30px', fontStyle: "normal", fontFamily:"Seymour One", fontWeight:300}}> You Already Manifesting...</b> */}
                {/* <b style={{ color: "#070707", fontSize:35, fontStyle: "normal", fontFamily:"Seymour One", fontWeight:400}}>Manifest </b> */}
                <b className={animate ? 'slide-in' : ''} style={{ color: "#070707", fontSize:'25px', fontStyle: "normal", fontFamily:"Seymour One", fontWeight:300}}> {energyList[index]}</b> 
              </h1>
            </small>
          </div>
          <div style={{ textAlign: "center" }}>
            <small className="text-muted">
              <h1>
                {/* <b style={{ color: "#070707", fontSize:'30px', fontStyle: "normal", fontFamily:"Seymour One", fontWeight:300}}> We Only Make It Conscious </b> */}
                {/* <b style={{ color: "#070707", fontSize:35, fontStyle: "normal", fontFamily:"Seymour One", fontWeight:400}}>Subliminals Anywhere</b> */}
                {/* <b style={{ color: "#070707", fontSize:'35px', fontStyle: "normal", fontFamily:"Seymour One", fontWeight:300}}>Anywhere...</b> */}
                {/* <b style={{ color: "#070707", fontSize:'30px', fontStyle: "normal", fontFamily:"Seymour One", fontWeight:300}}><b></b><b className={animate ? 'slide-in' : ''}> {energyList} </b> </b> */}
              </h1>
            </small>
          </div>
          
          <InputGroup hasValidation >
          {/* <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
          <Button className="resize-button"
              style={{ margin: 0,  marginTop: 30, borderRadius: 100,  height: 65, backgroundColor:'#FFFFFF' }}
              variant="light"
              onClick={() => setIsHuman(!isHuman)}
            >
              {isHuman ? (
                  <>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src="./spa.png" width="50" />
                    </div>
                  </>
                ) : (
                  <>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src="./spa2.png" width="50" />
                  </div>
                  </>
                )}
          </Button>
          </OverlayTrigger> */}

            <Form.Control 
              type="text"
              size="lg"
              autoComplete="off"
              placeholder="e.g: I am grateful for my new job."
              aria-describedby="inputGroupPrepend"
              // onChange={getData}
              onChange={handleSearchTextChange}
              value={searchText}
              required
              as="textarea"
              rows={5}
              style={{ color: "#7A7A7A", fontFamily: "Risque", fontStyle:"thin", fontWeight:100, margin: 10, borderRadius: 0, borderWidth: 3, 
              borderStyle: 'solid',
              borderImage: 'linear-gradient(to right, yellow, red) 1',
              boxSizing: 'border-box'}}
            />
            {/* <Button className="resize-button"
                style={{ margin: 0,  marginTop: 30, borderRadius: 100,  height: 65, backgroundColor:'#FFFFFF' }}
                variant="light"
                onClick={searchGreat}
              >
                <img src="./search2.png" width="45" /> 
            </Button> */}
            
            <Form.Control.Feedback type="invalid">
              Please input an affirmation.
              
            </Form.Control.Feedback>
            
          </InputGroup>
          <div>
              {error1 && (
                  <div style={{ color: 'red', marginTop: 5 }}>
                    {error1}
                  </div>
                )}
            </div>
          <div >
          
          <Button variant="danger" onClick={handleShowUpload} style={{ marginTop: 20, marginBottom: 10, borderRadius: 50,  height: 55, width:'45%'}}
            >
            < >
              <img src="./play5.png" width="30" style={{ marginRight: 5 }} />
              <span style={{color: 'white',}}> </span>
            </>
            
          </Button>
          <Button variant="danger" onClick={generateAffirmations} style={{ marginTop: 20, marginBottom: 10, borderRadius: 50,  height: 55, width:'45%', marginLeft:5 }}
            >
            {isLoading ? 'Loading...' : 

            < >
              <img src="./smart5.png" width="30" style={{ marginRight: 0 }} />
              <span style={{color: 'white',}}> Generate</span>
            </>
          }
          </Button>
        </div>

        </Form.Group>
        {/* <div style={{fontFamily: "Risque"}} className='searchBack' onClick={getSelectedHint}>
          {filteredHints.slice(0, 10).map((hint, index) => (
            <a href='#' key={index} onClick={() => setID(hint)}>
              <Button onClick={() => searchGreatHint(hint)} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', color: "#4E4E4E", margin:2, fontSize: 18}} value={hint.id} className="searchEntry">
                {hint}
              </Button>
            </a>
          ))}
        </div> */}
        
        {showTraits && (
        <>

          {/* <Container>
            <Row style={{ textAlign: 'left', marginTop: 35 }}>
              <Col style={{ fontFamily: "Risque", fontStyle: "bold", fontWeight: 300, fontSize: 20 }}> <img src="./trend.png" width="25" /> <b>Try Boosters</b></Col>
            </Row>
          </Container> */}

          {/* <Container style={{ fontFamily: "Risque", textAlign: 'left', marginTop: 5 }}>
            <Row>
              <Col sm>
                <Button onClick={() => searchGreatHint('fearless')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18 }}>Fearless</Button>
                <Button onClick={() =>searchGreatHint('attractive')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18  }}>Attractive</Button>
                <Button onClick={() =>searchGreatHint('creative')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18  }}>Creative</Button>
                <Button onClick={() =>searchGreatHint('rich')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18  }}>Rich</Button>
                <Button onClick={() =>searchGreatHint('sexy')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18  }}>Sexy</Button>
                <Button onClick={() =>searchGreatHint('strong')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18  }}>Strong</Button>
                <Button onClick={() =>searchGreatHint('funny')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18  }}>Funny</Button>
                <Button onClick={() => searchGreatHint('confident')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18  }}>Confident</Button>
              </Col>
            </Row>
          </Container> */}
        </>
      )}
    </Form>

    {/* <Form
    >
      <Form.Check style={{marginRight:20, marginTop:20}}
      reverse
        type="switch"
        id="custom-switch"
        label="Raw"
        onChange={() => setIsHuman(!isHuman)}

      >
        <img src="./face.png" width="45" /> 
      </Form.Check>

  
    </Form> */}
    

      {/* <div>
        <div className="row">
          <div className="col text-center">
            <a style={{ display: 'block', marginBottom: 20 }}>select a parallel life to visit</a>
          </div>
        </div>
      </div> */}

      <div style={{ textAlign: "center" }}>
        <div className="row">
          <div className="col text-center">
            {showGenerated && (
              <>
              <Container>
                <Row style={{ textAlign: 'left', marginTop: 40, marginBottom: 20 }}>
                  <Col style={{ textAlign: "center", fontFamily: "Roboto Flex", fontStyle: "bold", fontWeight: 200, color: '#4E4E4E', fontSize: 25 }}>
                    <b>{affirmations.length} Generated Affirmations</b>
                  </Col>
                </Row>
                 {/* {affirmations.map((item, index) => ( */}
                {/* maxHeight: '550px', overflowY: 'auto', */}
                <Row className="g-2" style={{ margin: '5px -2.5px', fontFamily: "Risque" }}>
                {affirmations.slice(0, visibleAffirmations).map((item, index) => (
                  <Col xs={12} sm={12} md={4} key={index} className="d-flex justify-content-center" style={{ marginBottom: 10 }}>
                      <Card
                        className="h-100"
                        style={{ backgroundColor: bColor, color: "#7A7A7A", fontFamily: "Risque", fontStyle:"thin", fontWeight:100, marginTop: 20, borderRadius: 0, borderWidth: selectedItems.includes(item.affirmation) ? 5 : 1, 
                              borderStyle: 'solid',
                              borderImage: 'linear-gradient(to right, grey, grey) 1',
                              boxSizing: 'border-box',
                              // borderColor: selectedItem === item.affirmation ? 'red' : 'green' // Conditional border color
                            }}
                        // onClick={()=> handleShowUpload2(item.affirmation)}
                        // style={{
                        //   width: '18rem',
                        //   margin: '10px',
                        //   cursor: 'pointer',
                        //   borderRadius: '15px',
                        //   border: '1px solid #ddd',
                        // }}
                      >
                        <Card.Body>
                          <Card.Text style={{ textAlign: 'center', fontFamily: "Risque", fontStyle: "thin", fontWeight: 100 }}>
                          <img
                            src={userPhoto}
                            style={{ width: 45, height: 45, borderRadius: '50%', marginRight: 10 }}
                          />
                            <a>{item.affirmation}</a>
                          </Card.Text>
                          <Card.Text style={{ textAlign: 'center', fontFamily: "Roboto Flex", fontWeight: 100, marginTop: 10 }}>
                            <a>Style:</a> <a style={{fontStyle: "italic"}}>{item.style}</a>
                          </Card.Text>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  marginTop:20, color: '#808B96', fontFamily: "Roboto Flex", fontSize: 12 }}>
                            <Button className="action-button" variant='light' style={{marginRight:0, width:'100%'}} onClick={(event) => {
                                event.stopPropagation(); // Prevents the card click event from being triggered
                                shareToFriends(selectedItems);
                              }}>
                              <img src="./share3.png" width="30" alt="Share" />
                            </Button>

                            <Button className="action-button" variant='light' style={{marginRight:0, width:'100%'}} onClick={(event) => {
                                event.stopPropagation(); // Prevents the card click event from being triggered
                                handleSelectItem(item.affirmation);
                              }}>                              
                              <img src= {selectedItems.includes(item.affirmation) ? "./save8.png" : "./save7.png"}  width="33" alt="save" />
                            </Button>
                            <Button className="action-button" variant='light' style={{marginRight:0, width:'100%'}} onClick={(event) => {
                              event.stopPropagation(); // Prevents the card click event from being triggered
                              // If selectedItems is empty, use item.affirmation, otherwise use selectedItems
                              const itemToUse = selectedItems.length === 0 ? item.affirmation : selectedItems;
                              handleShowUpload2(itemToUse);
                            }}>
                              <img src="./play2.png" width="30" alt="play" />
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
                {visibleAffirmations < affirmations.length && (
                  <Button className="action-button" variant="light" onClick={handleShowMore} style={{ marginTop: 20, marginRight:20, width:'100%'}}>
                    Show More
                  </Button>
                )}
                {visibleAffirmations > 5 && (
                  <Button className="action-button" variant="light" onClick={handleShowLess} style={{ marginTop: 20, width:'100%' }}>
                    Show Less
                  </Button>
                )}
              </Container>
    
              </>
            )}
            
          </div>
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        <div className="row">
          <div className="col text-center">
            {!showTraits && (
              <>
                <Container>
                  <Row style={{ textAlign: 'left', marginTop: 50, marginBottom: 30 }}>
                    <Col style={{ textAlign: "center", fontFamily: "Roboto Flex", fontStyle: "bold", fontWeight: 200, color: '#4E4E4E', fontSize:25 }}><b>{filteredCards.length} {data_up} Results: Click on Most-Relatable</b></Col>
                  </Row>
                </Container>
                <Row className="g-2" style={{ margin: '5px -2.5px', fontFamily: "Risque", }}>
                  {filteredCards.map((card, index) => (
                    <Col xs={12} sm={12} md={4} lg={3} key={index} className="d-flex justify-content-center" style={{ marginBottom: 10 }}>
                      <Card
                        className="h-100"
                        onClick={() => handleCardClick(data, card.docid, card.name, card)}
                        style={{ width: '18rem', 
                                margin: '10px', 
                                cursor: 'pointer',
                                borderRadius: '15px',
                                border: `0px solid ${borderColors[index % borderColors.length]}`, }}
                        >
                        <div style={{ width: '100%', height: '300px', overflow: 'hidden' }}>
                          <Card.Img variant="top" src={card.image} alt={card.name} style={{ width: '300px', height: '300px', objectFit: 'cover', borderTopRightRadius: '15px', borderTopLeftRadius: '15px' }} />
                        </div>
                        <div style={{ textAlign: 'center' }}></div>
                        <Card.Body>
                            <Card.Text>
                              <Card.Title>{card.name}</Card.Title>
                              <i style={{ fontFamily:"Roboto Flex", fontStyle:"thin", fontWeight:100 }}>{card.about}</i>
                            </Card.Text>
                          </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
                {isHuman? (
                  <Row>
                  <Col className="d-flex justify-content-center" style={{ marginTop: 20 }}>
                    <Button variant="link" onClick={() => setIsHuman(!isHuman)} 
                    style={{ 
                      textDecoration: 'none', color: '#4E4E4E'
                    }}>
                      <img src="./spa.png" width="50" /> 
                    </Button>
                  </Col>
                </Row>
                ):(
                  <Row>
                  <Col className="d-flex justify-content-center" style={{ marginTop: 20 }}>
                    <Button variant="link" onClick={() => setIsHuman(!isHuman)} 
                    style={{ 
                      textDecoration: 'none', color: '#4E4E4E', fontFamily: "Roboto Flex", fontStyle: "bold"
                    }}>
                      <img src="./spa2.png" width="50" /> 
                    </Button>
                  </Col>
                </Row>
                )
                }
              </>
            )}
            
          </div>

          

        </div>
      </div>


        {/* Modal component */}
      <Modal backdrop="static" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          {/* <Modal.Title>  </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          
          {!showNewContent ? (
            <>
            {/* <div style={{marginBottom:3, textAlign: 'center', color: '#C4C4C4'}}>
              {info1}
            </div> */}
            <div style={{marginBottom:5, textAlign: 'center',  color: '#C4C4C4'}}>
            {/* make count down here from 3:33 to 0 */}
              <h3>{formatTime(countdownTime)} minutes </h3>
              {/* This is a 3:33 minutes session */}
            </div>

            {selectedCard && (
                <>
                  <div style={{ textAlign: 'center' }}>
                    <img 
                      src={selectedCard.image} 
                      alt={selectedCard.name} 
                      style={{ 
                        width: '250px', 
                        height: '250px', 
                        borderRadius: '15px', 
                        objectFit: 'cover' 
                      }} 
                    />
                  </div>
                </>
              )}

            

            <div style={{ textAlign: 'center', marginTop: 10}}>

            {/* <div style={{marginLeft:20, marginRight:20, marginBottom:10,  textAlign: 'center',}}>
            {info2}
            </div> */}

              <h3>
              {/* {countdown > 0 ? (
           
                <h3>
                <Countdown date={Date.now() + 60 * 1000}/>
                </h3>
              ) : (
               
              )} */}

              {/* </h3> */}

                <>
                <h5 style={{marginTop:10, textAlign: 'center', fontWeight:200}}> <b> {frequency} x {giant}</b> <a>
                {/* <img src={"./info.png"} width="20" alt="info" /> */}
                </a> </h5> 


               {/* <h7 style={{marginTop:30, textAlign: 'center'}}>  </h7> */}
                {/* <div class="col"> */}
                  <input
                    style={{
                      borderRadius: 30,
                      marginBottom: 15,
                      height: 20,
                      width: '80%',
                      appearance: 'none',
                      background: `linear-gradient(to right, black 0%, black ${frequency * (100/(ranges[rangeValue]))}%, #CED4DA ${frequency * (100/(ranges[rangeValue]))}%, #CED4DA 100%)`
                    }}
                    variant="outline-dark"
                    type="range"
                    min="0"
                    max={ranges[rangeValue]}
                    value={frequency}
                    onChange={(e) => setFrequency(Number(e.target.value))}
                    disabled
                  />

                  {/* place playing... here */}
                  {/* {isIncreasing ? (
                    <p style={{ marginTop: 0, color: 'green', fontSize:17, color: '#C4C4C4',  }}>playing{' .'.repeat(dots)}</p>
                    ) : (
                      <p style={{ marginTop: 2, color: 'red', fontSize:17, color: '#C4C4C4' }}>click start</p>
                    )
                  } */}

                  <div>
                  <div>
                  <div style={{fontSize: 20, marginBottom:0, textAlign: 'center',  color: '#808B96', marginLeft:30}}> {subfreq} Hz</div>

                          <input
                          style={{
                              borderRadius: 30,
                              marginBottom: 20,
                              height: 10,
                              width: '80%',
                              appearance: 'none',
                              background: `linear-gradient(to right, #808B96 0%, #808B96 ${subfreq * (100/(20000))}%, #CED4DA ${subfreq * (100/(20000))}%, #CED4DA 100%)`
                            }}
                            variant="outline-dark"
                            type="range"
                            min="20"
                            max="20000"
                            value={subfreq}
                            onChange={(e) => setSubfreq(Number(e.target.value))}
                            step="10"
                          />
                          <div>
                            {audioUrls.map((url, index) => (
                              <audio style={{ display: 'none' }} autoPlay key={index} controls src={url}></audio>
                            ))}
                          </div>

                      </div>
                 
                </div>

     
                  <Button variant="light"
                    style={{ margin: 5, marginTop: 0, marginBottom: 10, borderRadius: 100, width: 120, height: 50 }}
                    onClick={handleStartStop}>
                    {isRestart ? (
                      <>
                        <img src="./restart.png" width="25" style={{ marginRight: 5 }} />
                        <span>restart</span>
                      </>
                    ) : isIncreasing ? (
                      <>
                        <img src="./fire5.png" width="25" style={{ marginRight: 5 }} />
                        <span style={{ color:"#F39C12" }} >pause</span>
                      </>
                    ) : (
                      <>
                        <img src="./fire6.png" width="25" style={{ marginRight: 5 }} />
                        <span> start</span>
                      </>
                    )}
                  </Button> 

                  {/* <Button variant="light"
                    style={{ margin: 5, marginTop: 0, marginBottom:10, borderRadius: 100, width: 120, height: 50 }}
                    onClick={handleButtonClick}>
                        <>
                          <img src="./back.png" width="20" style={{ marginRight: 5 }} />
                        </>
                  </Button> */}

                  {/* <Button variant="light"
                    style={{ margin: 5, marginTop: 0, marginBottom:10, borderRadius: 100, width: 120, height: 50 }}
                    onClick={handleButtonClick}>
                        <>
                          <img src="./next.png" width="20" style={{ marginRight: 5 }} />
                          <span> Next</span> 
                        </>
                  </Button> */}
                {/* </div> */}

                {/* {rangeValue !== 0 && ( */}
                        <Button 
                          variant="light" 
                          style={{ margin: 5, marginTop: 0, marginBottom: 10, borderRadius: 100, width: 120, height: 50 }}
                          // onClick={handleNext}
                          onClick={handleCloseModal}
                        >
                          <>
                            <img src="./done.png" width="25" style={{ marginRight: 5 }} />
                           <span> finish </span> 
                          </>
                        </Button>
                      {/* )} */}
                      
                  
                </>

              </h3>
            </div>
            {showSignInButton && (
                      <Button style={{width:'100%', marginTop:10}} variant="danger" onClick={signInWithGoogle}>
                        Sign in with Google
                      </Button>
                    )}
            </>
            ) : (
          <div style={{ textAlign: 'center' }}>
          <Form>
          {/* <Accordion defaultActiveKey="0" >
            <Accordion.Item eventKey="0">
              <Accordion.Header >
              <img src="./info.png" width="25" style={{ marginRight: 5 }} />
              <div style={{fontFamily:"Roboto Flex", color: '#636363',}}><i>Supercharge with Intention:</i> </div>
              </Accordion.Header>
              <Accordion.Body style={{backgroundColor:'#F4F4F4'}}>
                <div  style={{textAlign:"start", fontFamily:"Roboto Flex", textAlign:"start", fontSize: 14}}> 
                <img src="./info.png" width="25" style={{ marginRight: 5 }} />
               <i> Write one benefit someone else will gain from your transformation... </i> </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}
          {/* <div>
            Benefitiary: {otherOption}
          </div> */}
         
          {/* <div>
            Benefitiary: {otherOption}
          </div>
          <div>
            Energy : {selectedSmallCard}
          </div>
          <div>
            Size: {descriptions[rangeValue]}
          </div>
           */}
                {/* <div style={{ marginTop: 0, overflowY: 'auto' }}>
                    {Array.isArray(pastins) && pastins.length > 0 ? (
                      [...pastins].reverse().map((insight, index) => (
                        <div key={index} className="card my-3">
                          <div className="card-body d-flex align-items-center">
                            <img
                              src={userPhoto}
                              style={{ width: 25, height: 25, borderRadius: '50%', marginRight: 10 }}
                            />
                            <div style={{ fontSize:15 }} className="card-text mb-0">{insight}</div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div></div>
                    )}
                    {showSignInButton && (
                      <Button variant="danger" onClick={signInWithGoogle}>
                        Sign in with Google
                      </Button>
                    )}
                </div> */}
           
          {/* <Form.Group style={{ marginTop:10}} className="mb-3" controlId="exampleForm.ControlTextarea1">
          <div style={{ marginBottom:5, textAlign: 'left' }}>
            <Form.Text style={{ fontFamily: "Roboto Flex", fontSize: 12 }} className="text-muted">
              Understanding Manifestation (Optional)
            </Form.Text>
          </div>
            <Form.Select onChange={handleSelectChange} value={selectedOption} style={{ fontFamily: "Roboto Flex" }} aria-label="Default select example">
              <option value="">Where is the boundary between You and Nature?</option>
              <option value="the_air">The Air</option>
              <option value="my_skin">My Skin</option>
              <option value="my_body">My Body</option>
              <option value="my_brain">My Brain</option>
              <option value="my_thoughts">My Thoughts</option>
              <option value="my_feelings">My Feelings</option>
              <option value="my_memory">My Memory</option>
              <option value="no_boundary">Everything is Nature</option>
            </Form.Select>
           
          </Form.Group>    */}
          
          </Form>    
          {/* uncomment to restore insight diary */}
              <div>
                <Form.Group style={{ marginTop: 10, fontFamily: "Roboto Flex", display: "flex", alignItems: "center" }} className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                      onChange={handleInspirationChange}
                      value={inspirationText}
                      placeholder="post insights..."
                      as="textarea"
                      rows={3}
                      style={{ flexGrow: 1, marginRight: 5 }}
                    />
                    {/* <Button
                      
                      style={{ margin: 0, borderRadius: 100 }}
                      variant="light"
                      onClick={saveInsight}
                    >
                      <img src="./note.png" width="30" />
                    </Button> */}
                  </Form.Group> 
                </div>
                <div style={{ marginTop: 0, maxHeight: '250px', overflowY: 'auto' }}>
                    {Array.isArray(pastins) && pastins.length > 0 ? (
                      [...pastins].reverse().map((insight, index) => (
                        <div key={index} className="card my-3">
                          <div className="card-body d-flex align-items-center">
                            <img
                              src={insight.photo}
                              style={{ width: 35, height: 35, borderRadius: '50%', marginRight: 10 }}
                            />
                            <div style={{ fontSize:17 }} className="card-text mb-0">
                              {insight.text}
                            </div>
                            
                          </div>
                            <div style={{ margin: 5, color:'#808B96', fontFamily: "Roboto Flex", fontSize:12, textAlign:'right' }}>
                              <>{insight.time}</>
                              <> ({insight.date})</>
                            </div>
                        </div>
                      ))
                    ) : (
                      <div></div>
                    )}
                    {showSignInButton && (
                      <Button variant="danger" onClick={signInWithGoogle}>
                        Sign in with Google
                      </Button>
                    )}
                </div>      
          
          <Button variant="light" 
            style={{ margin: 5, marginTop: 20, marginBottom: 10, borderRadius: 100, width: 115, height: 55 }}
            onClick={handleBack}>
                  <>
                  <img src="./back.png" width="20" style={{ marginRight: 5 }} />
                  <span style={{color: '#636363',}}> Back</span>
                </>
          </Button>
          <Button variant="light" onClick={saveInsight} style={{ margin: 5, marginTop: 20, marginBottom: 10, borderRadius: 100, width: 115, height: 55 }}
            >
            < >
              <img src="./note.png" width="25" style={{ marginRight: 5 }} />
              <span style={{color: '#636363',}}> Post</span>
            </>
          </Button>
          {/* <div style={{ marginTop: 20 }}>
            Insights
          </div>
            <div style={{ marginTop: 15 }}>
                {Array.isArray(pastins) && pastins.length > 0 ? (
                  [...pastins].reverse().map((insight, index) => (
                    <div key={index} className="card my-3">
                      <div className="card-body d-flex align-items-center">
                        <img
                          src={userPhoto}
                          style={{ width: 35, height: 35, borderRadius: '50%', marginRight: 10 }}
                        />
                        <p className="card-text mb-0">{insight}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p> 
                 
                  </p>
                )}
                {showSignInButton && (
                  <Button variant="danger" onClick={signInWithGoogle}>
                    Sign in with Google
                  </Button>
                )}
            </div>  */}

          </div>
          
          
            )}

            
        </Modal.Body>
        {/* <Modal.Footer>
        
        <Link to="/play-audio">
          <Button variant="light" 
            style={{ margin: 5,  marginTop: 5, borderRadius: 100, width: 150, height: 60 }}
            onClick={handleCloseModal}>
            <img src="./upload2.png" width="25" />
          </Button>
        </Link>
        </Modal.Footer> */}
      </Modal>


      <Modal
        show={settings}
        onHide={handleCloseSettings}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group style={{ marginTop:10}} className="mb-3" controlId="exampleForm.ControlTextarea1">
          <div style={{ marginBottom:5, textAlign: 'left' }}>
            <Form.Text style={{ fontFamily: "Roboto Flex", fontSize: 12 }} className="text-muted">
              Affirmation?
            </Form.Text>
          </div>
            <Form.Select onChange={handlePowerChange} value={selectedPower} style={{ fontFamily: "Roboto Flex" }} aria-label="Default select example">
              <option value=""> Choose Affirmation? </option>
              <option value="Remember_how">Remember How (recommended)</option>
              <option value="Remember_you">Remember You Are (recommended)</option>
              <option value="Acknowledge">Acknowledge</option>
              <option value="Because">Because You are</option>
              <option value="Sooner">Sooner or Later</option>
              <option value="I_find">I Find You To Be</option>
              <option value="Be_Like_If">What Would It Be Like If</option>
              <option value="Like_when">What’s It Like When</option>
              <option value="Realize">Realize</option>
              <option value="Imagine">Imagine</option>
              <option value="Suppose">Suppose</option>
              <option value="Notice">I Notice You Are</option>
              <option value="I_allow">You allow Yourself to be (recommended)</option>
            </Form.Select>

            <div style={{marginTop: 20}}>
              {selectedPower &&(
              <div className="text-muted" style={{fontFamily: "Roboto Flex", fontSize: 16, textAlign: 'center'}}>
                    e.g: "{explanation}"
              </div>
              )}
            </div>
            
            <div style={{ marginBottom:5, marginTop:35, textAlign: 'left' }}>
              <Form.Text style={{ fontFamily: "Roboto Flex", fontSize: 12 }} className="text-muted">
                Number of Repetitions?
              </Form.Text>
            </div>
            <div style={{ textAlign: 'center', marginTop: '5px', height:'80px' }}>
                <input
                  style={{
                    borderRadius: 30,
                    marginTop: 10,
                    height: 10,
                    width: '80%',
                    appearance: 'none',
                    background: `linear-gradient(to right, black 0%, black ${rangeValue * 50.00}%, #CED4DA ${rangeValue * 50.00}%, #CED4DA 100%)`
                  }}
                  variant="outline-dark"
                  type="range"
                  min="0"
                  max="2"
                  value={rangeValue}
                  onChange={handleRangeChange}
                />
                <div style={{ color:'#808B96', marginTop: '5px', fontSize: '16px' }}>
                  {ranges[rangeValue]} Reps =  {formatTime(freqTop)} mins
                </div>

                {/* <div style={{marginTop: 10}}> */}
                {/* className="text-muted" style={{ fontFamily: "Roboto Flex", fontSize: 16, textAlign: 'center' }} */}
                  {/* {selectedPower &&( */}
                 
                    {/* <div className="text-muted" style={{ fontFamily: "Roboto Flex", fontSize: 16, textAlign: 'center' }}>
                      {formatTime(freqTop)} mins
                    </div> */}
                  {/* )} */}
                {/* </div> */}

                
              </div>
           
          </Form.Group> 

          

          <div style={{display: 'flex', justifyContent: 'center', marginBottom: 20,}}>
          <audio ref={audioRef} src="https://firebasestorage.googleapis.com/v0/b/troopsong-1a530.appspot.com/o/Alloy_tts-1-hd_1x_2024-07-14T07_04_16-954Z.mp3?alt=media&token=74b3be07-dc8f-4a47-9b8d-ac1df58c79f7" />
          <Button variant="light"
                    style={{ margin: 5, marginTop: 50, borderRadius: 100, width: 130, height: 55, color: '#636363'}}
                    onClick={playInfo}>
                      <>
                        {/* <img src="./play.png" width="25" style={{ marginRight: 5 }} /> */}
                        <span>{isPlaying ?  
                        (
                      <>
                        <img src="./play.png" width="25" style={{ marginRight: 5 }} />
                        <span style={{ color:"#636363" }} > intro </span>
                      </>
                    )
                        : 
                        (
                      <>
                        <img src="./play.png" width="25" style={{ marginRight: 5 }} />
                        <span style={{ color:"#636363" }} > intro </span>
                      </>
                    )
                        
                        }</span>
                        </>
                    
            </Button>

            <Button variant="light"
                    style={{ margin: 5, marginTop: 50, borderRadius: 100, width: 130, height: 55, color: '#636363' }}
                    onClick={handleStart}>
                      <>
                        <img src="./play2.png" width="25" style={{ marginRight: 5 }} />
                        <span> begin</span>
                      </>
                    
            </Button> 
          </div>
           
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSettings}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>


      <Modal show={showupload} onHide={handleCloseUpload}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Play Sub</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
       
          <div>
                  {/* <Form.Group style={{ marginTop: 10, fontFamily: "Roboto Flex", display: "flex", alignItems: "center" }} className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <div >
                      <input type="file" onChange={handleFileChange} />
                      <button onClick={handleUpload}>Display Photo</button>
                    </div>
                  </Form.Group> */}
                  {/* <Figure>
                    <Figure.Image
                      width={180}
                      height={180}
                      alt="171x180"
                      src=".\placeholder.png"
                    />
                  </Figure> */}

                <Form.Group style={{ marginTop: 10, fontFamily: "Roboto Flex", display: "flex", alignItems: "center" }} className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                      // className="gradient-background"
                      onChange={handleSearchTextChange}
                      value={searchText}
                      placeholder="Write your affirmations"
                      as="textarea"
                      rows={4}
                      style={{ backgroundColor: bColor, color: "#7A7A7A", fontFamily: "Risque", fontStyle:"thin", fontWeight:100, marginTop: 20, borderRadius: 0, borderWidth: 3, 
                              borderStyle: 'solid',
                              borderImage: 'linear-gradient(to right, grey, grey) 1',
                              boxSizing: 'border-box'}}
                    />

                  </Form.Group> 

          </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20, marginRight: 10, marginLeft: 10 }}>
              {/* Left Side - Wheel Gif and Play2 Icon */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src="./wheel.gif" width="25" height="25" style={{ marginLeft: 0 }} /> 
               <span style={{ marginLeft: 5, fontSize: 15, color: '#808B96' }}>{numofmp3}</span> 

                
              </div> 

              <div style={{ fontSize: 15, color: '#808B96', display: 'flex', alignItems: 'center', marginLeft: 15 }}>
                  <img src="./play2.png" width="25" height="25" style={{ marginRight: 5 }} />
                  {counter + lllastCount}
                </div>

              {/* Right Side - Skip Icon and Next Text */}
              {/* <div onClick={getNextTestimony} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginLeft: 'auto', cursor: 'pointer' }}>
                <img src="./face2.png" width="30" height="30" style={{ marginRight: 2 }} />
                {testimonies.length > 0 && (
                  <i style={{ fontSize: 12, color: '#808B96' }}>
                    {testimonies[currentResultIndex].by}
                  </i>
                )}
              </div> */}
            </div>

            <Accordion defaultActiveKey={null} style={{ marginBottom: 10 }} onSelect={handleToggle}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <img
                    src={isAccordionOpen ? "./net5.png" : "./net.png"}
                    width="25"
                    style={{ marginRight: 5 }}
                  />
                  <div style={{ fontFamily: "Roboto Flex", color: '#636363' }}>
                    <i>Use ChannelingWheel? </i>
                  </div>
                </Accordion.Header>
                <Accordion.Body style={{ backgroundColor: '#F4F4F4' }}>
                  <div id="wheel"></div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center', // Vertically center the text
                      textAlign: 'center', // Horizontally center the text
                      marginBottom: 25,
                      fontSize: 14,
                      height: '100%', // Make sure the div has height to center vertically
                    }}
                  >
                    <div>
                      <label htmlFor="speedInput">
                        {subIndexG == null ? (
                          <a> Play and Save to begin channelling. </a>
                        ) : (
                          <>
                            Focus on the speed of the circle to channel the energy from the
                            <b> {numberToWords(population)} Lives, both past or present,</b>{' '}
                            <a>
                              {showFullText
                                ? popExplanation.substring(popExplanation.indexOf('who')) // Show full text
                                : `${popExplanation.substring(popExplanation.indexOf('who'), popExplanation.indexOf('who') + 25)}...`} {/* Show first 25 characters */}
                            </a>
                            <span
                              style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}
                              onClick={toggleText}
                            >
                              {showFullText ? 'read less' : 'read more'}
                            </span>
                          </>
                        )}
                      </label>
                      {/* Instruction player */}
                      <audio controls style={{ marginTop: 15 }}>
                        <source
                          src="https://firebasestorage.googleapis.com/v0/b/energyvoo.appspot.com/o/testimonies%2FElevenLabs_2024-09-10T16_21_44_Angelina_gen_s50_sb75_se0_b_m2.mp3?alt=media&token=dfece892-c18a-40fe-9e73-8b79966466d5"
                          type="audio/mpeg"
                        />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion style={{ marginBottom: 10 }} eventKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header> <Badge style={{ marginRight: 10 }} 
                bg='danger'
                // bg={notifications==0 ? 'secondary' : 'danger'}
                >
                {notifications}
                {/* {notifications==0 ? <img src="./podcast.png" width="22" style={{}} /> : notifications } */}
                </Badge>
                   <i style={{ marginRight: 10, fontFamily: "Roboto Flex", color: '#636363' }}>See ResultCousins?</i>
                </Accordion.Header>
                <Accordion.Body>
                    <Row style={{ marginBottom: 20, maxHeight: '200px', overflowY: 'auto', marginTop: 0, marginBottom: 25 }}>
                      {testimonies && testimonies.length > 0 ? (
                        testimonies.map((testimony, index) => (
                          <Col key={index} xs={12}>
                            <Card style={{ borderStyle: 'solid', margin: 10, borderRadius: 0, boxSizing: 'border-box' }} className="mb-3">
                              <Card.Body>
                                <Card.Text>
                                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 10, marginBottom:10 }}>
                                    By {testimony.by} on {testimony.on} :
                                  </div>
                                  <i>
                                  </i>
                                  <div style={{ marginRight: 10, marginTop: 20, marginBottom: 15, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <Button 
                                    variant={testimony.played === true ? 'secondary' : 'dark'} 
                                    onClick={() => toggleAudio(testimony.mp3, testimony.by, testimony.docid, index)} 
                                    style={{ display: 'flex', alignItems: 'center', borderRadius: 0 }}
                                  >
                                    
                                    {playingIndex === index ? <img src="./pause.png" width="25" style={{ marginRight: 5 }} /> : <img src="./play5.png" width="25" style={{ marginRight: 5 }} />}
                                  </Button>

                                  </div>
                                  {/* <a>
                                    <b>Principles:</b> 
                                    <i>
                                            {showFullText ? testimony.principles : `${testimony.principles.substring(0, 35)}...`}
                                          </i>
                                            <span style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }} onClick={toggleText}>
                                              {showFullText ? 'read less' : 'read more'}
                                            </span>
                                  </a> */}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))
                      ) : (
                        <Col xs={12}>
                          <div style={{ marginLeft:10, marginRight:10, marginButtom:10, textAlign: 'center', color: '#808B96', fontSize: 16 }}>
                            Sign in to play Result Reenactments
                          </div>
                        </Col>
                      )}
                  </Row> 
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>




            
              <div>
                <Form.Select aria-label="Default select example" style={{ height: 50 }} value={selectedBackground} onChange={handleSelectBackground}>
                  <option> 🎵 Choose Background Sound</option>
                  <option value="1">No Sound</option>
                  <option value="2">Aum Chant 1</option>
                  <option value="3">Aum Chant 2</option>
                  <option value="4">Fire</option>
                  <option value="5">Forest</option>
                  <option value="6">Guitar</option>
                  <option value="7">Ocean Waves</option>
                  <option value="8">Rain</option>
                  <option value="9">River</option>
                  <option value="10">White Noise</option>
                </Form.Select>
                <audio ref={audioRef} style={{ display: 'none' }}></audio>
              </div>

              {/* <div>Timer: {timer} seconds</div> */}
              {/* <div>Counter: {counter}</div> */}

              

              
              <input
                style={{
                      borderRadius: 30,
                      marginBottom: 0,
                      marginTop: 20,
                      height: 30,
                      width: '100%',
                      appearance: 'none',
                      background: `linear-gradient(to right, #808B96 0%, #808B96 ${subfreq * (100/(20000))}%, #CED4DA ${subfreq * (100/(20000))}%, #CED4DA 100%)`
                    }}
                    variant="outline-dark"
                    type="range"
                    min="20"
                    max="20000"
                    value={subfreq}
                    // onChange={(e) => setSubfreq(Number(e.target.value))}
                    onChange={handleFrequencyChange}
                    step="10"
                      />
              <div style={{ textAlign: 'center', marginTop: 2, color: '#808B96' }}>
                <span style={{ fontSize: 11 }}> 17500Hz - 20000Hz (Subliminal)</span>
              </div>
              <div style={{fontSize: 18, marginBottom:20, textAlign: 'center',  color: '#808B96'}}> {subfreq} Hz ({audibility})</div>
              <div style={{display: 'flex', justifyContent: 'center' }}>
                {audioUrls.map((url, index) => (
                  <audio key={index} controls src={url} autoPlay loop className="no-download" ref={(el) => (audioRefs.current[index] = el)} onLoadedMetadata={handleLoadedMetadata}></audio>
                ))}
              </div>
              {/* <div>
                {duration > 0 && (
                  <p>Audio Length: {Math.floor(duration)} seconds</p>
                )}
              </div> */}
              <div style={{display: 'none', justifyContent: 'center', marginTop:5 }}>
                {audio1Urls.map((url1, index) => (
                  <audio style={{ display: 'none' }} autoPlay key={index} controls src={url1} loop className="no-download"></audio>
                ))}
              </div>
              {error && (
                  <div style={{margin:5}} className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                {error2 && (
                  <div className="alert alert-danger" role="alert">
                    {error2}
                  </div>
                )}
                {error1 && (
                  <div className="alert alert-danger" role="alert">
                    {error1}
                  </div>
                )}
                {error3 && (
                  <div className="alert alert-danger" role="alert">
                    {error3}
                  </div>
                )}

                {/* **TODO: After signing in in the play screen, it does the weird thing of bringing the playlist to playscreen */}
              <div style={{display: 'flex', justifyContent: 'center', marginTop:20, marginBottom:10 }}>
                <Button variant="danger" onClick={() => { if (!isLoading) convertTextToAudio(searchText, subfreq) }} disabled={isLoading} style={{ margin: 5, marginTop: 20, marginBottom: 10, borderRadius: 50, width: 130, height: 55 }}>
                  {isLoading ? 'Loading...' : <><img src="./play3.png" width="25" style={{ marginRight: 5 }} /> Play </>}
                </Button>
                {showsave && <Button variant="danger" onClick={handleUploadSub} style={{ margin: 5, marginTop: 20, marginBottom: 10, borderRadius: 50, width: 130, height: 55 }}>
                <img src="./cloud2.png" width="25" style={{ marginRight: 5 }} />
                    Save
                </Button>}
                
                {/* <Button variant="light" onMouseDown={handleMouseDown}
                                        onMouseUp={handleMouseUp}
                                        onTouchStart={handleMouseDown}
                                        onTouchEnd={handleMouseUp}
                                        onMouseLeave={handleMouseLeave}
                                        onContextMenu={handleContextMenu}
                                        style={{ margin: 5, marginTop: 20, marginBottom: 10, borderRadius: 5, width: 125, height: 55, color: "#FF4109"}}>
                    I Receive
                </Button> */}
              </div>

              
                
        </Modal.Body>
{/* TODO:before canceling ask if they have saved it? */}
{/* TODO:play and pause explanation? */}

        
      <Form.Group style={{color:'#808B96', display: 'flex', justifyContent: 'center',}} className="mb-3" controlId="formBasicCheckbox">
      <Form.Check type="checkbox" label="Activate Subwheel?" checked={lllivingSub} onChange={handleLivingCheckBox} />
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipAlive}
          >
          <img src="./help22.png" width="20" height="20" style={{ marginLeft: 3 }} />
        </OverlayTrigger>
      
      </Form.Group>
       

        {showSignInButton && (
                  <Button style={{marginBottom: 0}}variant="danger" onClick={signInWithGoogle}>
                    Sign in with Google
                  </Button>
                )}

        {/* <div style={{ textAlign: 'start', marginTop: 25, color: '#808B96', marginBottom:5 }}>
          <span style={{ marginLeft: 15, fontSize: 18, }}> <img src="./trend.png" width="25" /> Play Results: </span>
        </div> */}
        {/* <Row style={{ marginBottom: 20, maxHeight: '200px', overflowY: 'auto', marginTop: 0, marginBottom: 25 }}>
            {testimonies && testimonies.length > 0 ? (
              testimonies.map((testimony, index) => (
                <Col key={index} xs={12}>
                  <Card style={{ borderStyle: 'solid', margin: 10, borderRadius: 5, boxSizing: 'border-box' }} className="mb-3">
                    <Card.Body>
                      <Card.Text>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 10, marginBottom:10 }}>
                          By {testimony.by} on {testimony.on}:
                        </div>
                        <i>
                        </i>
                        <div style={{ marginRight: 10, marginTop: 20, marginBottom: 15, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button variant='dark' onClick={() => toggleAudio(testimony.mp3, testimony.by, index)} style={{ display: 'flex', alignItems: 'center' }}>
                          <img src="./play3.png" width="25" style={{ marginRight: 5 }} />
                          {playingIndex === index ? 'Pause Result' : 'Play Result'}
                        </Button>
                        </div>
                        <a>
                          <b>Principles:</b> 
                          <i>
                                  {showFullText ? testimony.principles : `${testimony.principles.substring(0, 35)}...`}
                                </i>
                                  <span style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }} onClick={toggleText}>
                                    {showFullText ? 'read less' : 'read more'}
                                  </span>
                        </a>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <Col xs={12}>
                <div style={{ marginLeft:10, marginRight:10, marginButtom:10, textAlign: 'center', color: '#808B96', fontSize: 16 }}>
                  We Will Post Reenactment of Testimonies/Results Here...
                </div>
              </Col>
            )}
        </Row> */}
       
      </Modal>

      <Modal show={showMysub} onHide={handleCloseMySub}>
        <Modal.Header closeButton>
          <Modal.Title>My Playlist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {subliminals.length === 0 ? (
            <div style={{ textAlign: 'center', color: '#808B96', fontFamily: 'Roboto Flex', fontSize: 14, marginTop: 20 }}>
              <p>You haven't saved any Subliminals yet.</p>
            </div>
          ) : (
            <>
        <Row style={{ marginBottom:20, maxHeight: '400px', overflowY: 'auto'}}>
            {subliminals.slice().reverse().map((subliminal, index) => (
              <Col key={index} xs={12} >
                <Card style={{borderStyle: 'solid',
                              borderImage: 'linear-gradient(to right, grey, grey) 1',
                              boxSizing: 'border-box',}} className="mb-3" onClick={() => handleSubCardClick(subliminal, subliminals.length - 1 - index)}>
                  <Card.Body>
                      {subliminal.livingSub && (
                      <img
                        src="./wheel.gif"
                        width="45"
                        height="45"
                        alt="Living Subliminal"
                        style={{ position: 'absolute', top: 10, right: 15 }}
                      />
                    )}
                  <Card.Text>
                    <img
                            src={userPhoto}
                            style={{ width: 35, height: 35, borderRadius: '50%', marginRight: 10 }}
                          />
                    {/* <div className="card-body d-flex align-items-center">
                        
                        <p className="card-text mb-0"> */}
                        
                        {subliminal.text.split(' ').slice(0, 10).join(' ')}
                        {/* <span>
                        {subliminal.livingSub ? 
                          <img src="./fyre.gif" width="50" height="50" alt="Living Subliminal" />
                          : ''}
                        </span> */}
                        
                        {/* </p>
                      </div> */}
                  </Card.Text>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  marginTop:20, color: '#808B96', fontFamily: "Roboto Flex", fontSize: 12 }}>
                              <Button variant='light' style={{marginRight:20}} onClick={(event) => {
                                  event.stopPrFopagation(); // Prevents the card click event from being triggered
                                  shareToFriends(subliminal.text);
                                }}>
                                <img src="./share.png" width="28" alt="Share" />
                              </Button>
                              {/* <span>
                                <>{subliminal.time}</>
                                <> ({subliminal.date})</>
                              </span> */}
                              <Button variant='light' style={{marginRight:0}} onClick={(event) => {
                                 event.stopPropagation();
                                handleDelete(subliminals.length - 1 - index)}
                                }>
                                <img src="./delete.png" width="28" alt="Delete" />
                              </Button>
                            </div>
                            
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          {/* ***TODO:arrange this button */}
          {/* {currentSubIndex + 10 < subliminals.length && (
            <Button variant="light" style={{ display: "flex", alignItems: "center",  borderRadius: 5,  color: "#FF4109"}} onClick={handleLoadMore} >
              More
            </Button>
          )} */}
          </>
        )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseMySub}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseMySub}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>

      
      {/* <Modal show={showupload} onHide={handleCloseUpload}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Sub</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{ marginTop: 0, fontFamily: "Roboto Flex" }}>
          Woohoo, the sub you searched for isn't available yet...
        </div>
          <div>
                <Form.Group style={{ marginTop: 10, fontFamily: "Roboto Flex", display: "flex", alignItems: "center" }} className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                      onChange={handleEnergyChange}
                      value={energyval}
                      placeholder="new sub..."
                      as="textarea"
                      rows={1}
                      style={{ flexGrow: 1, marginRight: 5 }}
                    />

                  </Form.Group> 

                  <Form.Group style={{ marginTop: 10, fontFamily: "Roboto Flex", display: "flex", alignItems: "center" }} className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                      onChange={handleNameChange}
                      value={namevalue}
                      placeholder="like ?"
                      as="textarea"
                      rows={1}
                      style={{ flexGrow: 1, marginRight: 5 }}
                    />
                    
                  </Form.Group>

                  <Form.Group style={{ marginTop: 10, fontFamily: "Roboto Flex", display: "flex", alignItems: "center" }} className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <div >
                      <input type="file" onChange={handleFileChange} />
                    </div>
                  </Form.Group>
                </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleUpload}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}


      <Modal show={showPay} onHide={handleClosePay}>
        <Modal.Header closeButton>
          <Modal.Title>Purchase Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CardElement />
          {error && <div>{error}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePay}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCheckout} disabled={!stripe || isLoading}>
            {isLoading ? 'Processing…' : 'Subscribe'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Home;
