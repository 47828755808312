// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import AudioPlayer from './AudioPlayer';
import Home from './Home';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Container, Form, Button, Modal } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Offcanvas from 'react-bootstrap/Offcanvas';
// import GoogleSignIn from './GoogleSignIn';
// import {GoogleLogin} from '@react-oauth/google'
// import { jwtDecode } from "jwt-decode";
// import { auth, provider } from './firebase'; // Import your Firebase configuration
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import mixpanel from 'mixpanel-browser';
import { Analytics } from "@vercel/analytics/react"
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// / create an instance of the mixpanel client
mixpanel.init("39bfdefaef01741c15f1abff7f53bffb", {track_pageview: true});

const auth = getAuth();
const firestore = getFirestore();

// Load your Stripe publishable key
const stripePromise = loadStripe('your-publishable-key-here');


function App() {
  const [conversation, setConversation] = useState(null);
  const [show, setShow] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userPhoto, setUserPhoto] = useState('');
  const [showMysub, setShowMySub] = useState(false);

  const handleCloseMySub = () => setShowMySub(false);
  const handleShowMySub = () => {
    mixpanel.track('mysubsfromup', {
      // message: "card clicked while signed in"
    })
    setShowMySub(true);
  }


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // console.log('app conversation1: ', conversation)

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setIsSignedIn(true);
        setUserName(user.displayName);
        setUserPhoto(user.photoURL);
        setShowMySub(true)
      } else {
        setIsSignedIn(false);
        setUserPhoto('');
        setUserName('')
      }
    });
  }, []);

  const signInWIthGoogle = async () => {

    mixpanel.track('signIn', {
      message: "signin button clicked"
    })

    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      console.log(result);
      const guser = result.user.displayName;
      const userEmail = result.user.email;
      const userPhoto = result.user.photoURL;
      const userID = result.user.uid; // Get the user ID
      console.log(guser);
  
      setUserName(guser);
      setUserPhoto(result.user.photoURL);
      setIsSignedIn(true);
  
      // Check if user document exists in 'users' collection
      const userDocRef = doc(firestore, 'users', userID);
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (!userDocSnapshot.exists()) {
        // Create the user document if it doesn't exist
        await setDoc(userDocRef, {
          displayName: guser,
          photoURL: userPhoto,
          userId: userID,
          email: userEmail
          // Add other user details here if needed
        });
        console.log("User document created");
      } else {
        console.log("User document already exists");
      }
  
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.error("Error during sign-in:", error);
      // ...
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setIsSignedIn(false);
        setUserName('');
        setUserPhoto('');
      })
      .catch((error) => {
        console.error("Error during sign-out:", error);
      });
  };
  

  //   const onSignInSuccess = (googleUser) => {
  //     const profile = googleUser.getBasicProfile();
  //     console.log('ID: ' + profile.getId());
  //     console.log('Name: ' + profile.getName());
  //     console.log('Image URL: ' + profile.getImageUrl());
  //     console.log('Email: ' + profile.getEmail());
  //     // Handle the signed-in user's info here
  // };

  // const onSignInFailure = (error) => {
  //     console.error('Sign-in error', error);
  //     // Handle sign-in failure here
  // };

  const handleConversationTransfer = (conversationText) => {
    console.log('app conversation2: ', conversationText)
    setConversation(conversationText)
  };

  // const handlePhotoTransfer = (conversationText) => {
  //   console.log('photo: ', conversationText)
  //   setConversation(conversationText)
  // };

  const mysubs = () => {
    handleShowMySub();
  };

  return (
    <div className="App">
      {/* <div style={{ flex: 1 }} className="container" > */}
      <div className="container-fluid p-2" >
        <nav bg="dark" className="navbar navbar-expand navbar-dark" style={{"padding":"0px", margin:0}} >
            <a href="https://www.playsubs.com" className="navbar-brand" style={{"color": '#CCCCCC', margin:5}} > <img src="./logo.png" width="25" alt="logo" />  </a>
            
            <div class="collapse navbar-collapse" id="toggleMobileMenu">
              <ul class="navbar-nav">
              </ul>
            </div>
            <div class="collapse navbar-collapse" id="toggleMobileMenu">
              <ul class="navbar-nav">
              </ul>
            </div>
            <div style={{align:'right'}}class="collapse navbar-collapse" id="toggleMobileMenu">
            <ul class="navbar-nav">
            <span class="navbar-text">
              {/* <a onClick={handleShow} class="navbar-brand" style={{"color": '#CCCCCC', margin:5}} > <img src="./menu.png" width="30" />  </a> */}
            </span> 
            </ul>
            </div> 
        
            <span class="navbar-text">
            {/* <Button variant="primary" onClick={handleShow}>
              Launch
            </Button> */}
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav mr-auto">
                {/* display profile photo here */}
                <li className="nav-item">
                <a onClick={handleShowMySub}>
                {/* <a onClick={show ? handleClose : handleShow}> */}
                  <img
                    src={isSignedIn && userPhoto ? userPhoto : "./account.png"}
                    alt={userName || "placeholder"}
                    style={{ width: 35, height: 35, borderRadius: '50%' }}
                  />
                </a>
                </li>
              </ul>
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <a onClick={show ? handleClose : handleShow} className="nav-link" style={{ color: '#CCCCCC', margin: 5 }}>
                      <img src={show ? "./close.png" : "./menu.png"} width="35" alt="menu" />
                    </a>
                  </li>
                </ul>
            </div>
            
            </span> 
            
            
        </nav>
    </div>
    {/* <div className="alert alert-warning" role="alert" style={{ margin: '20px' }}>
        We are experiencing some technical issues. Please check back in an hour. Thank you.
      </div> */}

      <Router>
      <Container fluid className="p-2 mt-5">
       {/* <Container className="mt-5"> */}
       <Elements stripe={stripePromise}>

        <Routes>
            <Route path="/" element={<Home stripe={stripePromise} handleConversationTransfer={handleConversationTransfer} userPhoto={userPhoto} userName={userName} showMysub={showMysub} handleCloseMySub={handleCloseMySub} handleShowMySub={handleShowMySub}/>} />
            <Route path="/play-audio" element={<AudioPlayer conversation={conversation}/>} />
        </Routes>
        </Elements>
      </Container>
    </Router>

    <Offcanvas scroll={true} placement={'top'} show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <a href="https://www.playsubs.com" class="navbar-brand" style={{"color": '#CCCCCC', margin:5}} > <img src="./logo.png" width="25" />  </a>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <div>
            {/* <img src="./progress.png" width="25" /> <>My Subs</> */}
            <Button variant='light' 
              style={{ borderColor: '#C7BEBE', margin: 4, marginTop: 8, height: 50, borderRadius: 100, fontSize: 20 }} 
              className="w-100" 
              onClick={handleShowMySub}>
               <img src="./progress.png" width="25" /> <>My Playlist</>
            </Button>
            </div>
            {/* <div style={{marginTop:8}}>
            <img src="./earn.png" width="25" /> <>Upload Voo & Earn</>
            </div> */}
            {/* <div style={{marginTop:8}}>
            <img src="./premium.png" width="25" /> <>Help Us Work Fulltime</>
            </div> */}
            <Button variant={isSignedIn ? 'light':'danger'} 
              style={{ borderColor: '#C7BEBE', margin: 4, marginTop: 8, height: 50, borderRadius: 100, fontSize: 20 }} 
              className="w-100" 
              onClick={isSignedIn ? handleSignOut : signInWIthGoogle}>
                {isSignedIn ? `Sign Out (${userName})` : 'Sign In with Google'}
            </Button>
        </Offcanvas.Body>
    </Offcanvas>
    </div>
  );
}

export default App;
