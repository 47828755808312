// AudioPlayer.js
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Form, Modal, FloatingLabel,} from 'react-bootstrap';
// var audioUrl = ''
// const { exec } = require('child_process');
import Spinner from 'react-bootstrap/Spinner';
import Stack from 'react-bootstrap/Stack';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';




const voices = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer']

function AudioPlayer({ conversation}) {
    // console.log("conversation transfered: ", conversation)
    const location = useLocation();
    const conversationText = location.state?.conversationText || '';
    const [audioUrls, setAudioUrls] = useState([]);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [zoomModalShow, setZoomModalShow] = useState(false);
    const [prayerType, setPrayerType] = useState(false);
    const [progress, setProgress] = useState(0);
    const audioRef = useRef(null);
    const [body, setBody] = useState(false)
    const [topUpTimer, setTopUpTimer] = useState(null);
    const [replayCount, setReplayCount] = useState(0);
    const [countdown, setCountdown] = useState(60); // Initial countdown value
    const [name, setName] = useState(null);
    const [itisdone, setItisdone] = useState(null);
    const [conversation2, setConversation2] = useState(null);
    const options = ["flow", "blockage", "distraction", "emptyness", "no energy"];

    console.log('audio conversation: ', conversation)

    // Function to start the top up timer and increase progress
    const startTopUp = () => {
        const timer = setInterval(() => {
            setProgress(prevProgress => Math.min(prevProgress + 1, 100));
            setCountdown(prevCountdown => Math.max(prevCountdown - 1, 0)); // Decrease countdown
        }, 100); // Increase progress every 100 milliseconds
        setTopUpTimer(timer);
    };

    // Function to stop the top up timer
    const stopTopUp = () => {
        clearInterval(topUpTimer);
        setTopUpTimer(null);
    };

    // console.log("type: ", type)

    useEffect(() => {
      pauseAudio(); // Trigger pauseAudio function when AudioPlayer page loads
    }, []);


    useEffect(() => {
      if (conversationText) {
        // console.log('audio conversation2: ', conversation)
        setConversation2(conversationText)
        convertTextToAudio(conversationText);
      }
    }, [conversationText]);

    // Restarts the countdown timer
    const resetCountdown = () => {
      setCountdown(60);
  };

    const closeModal = () => {
      setModalShow(false);
      pauseAudio();
      resetCountdown();
      //   if (audioRef.current) {
      //     audioRef.current.play(); // Resume audio playback
      // }
    };

    const closeZoomModal = () => {
      setZoomModalShow(false);
    };

      const pauseAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause(); // Pause audio playback
        }
        stopTopUp();
        // setModalShow(true); // Show modal
    };

    // const fetchConversation = async () => {
    //   // setLocalItisdone(itisdone);
    //   try {
    //     const response = await axios.post(
    //       'https://api.openai.com/v1/chat/completions',
    //       {
    //         "model": "gpt-4",
    //         "messages": [
    //           {
    //             "role": "user",
    //             "content": 
                
    //             `
    //                firstly classify and choose the closest category this statement "${itisdone}" falls under from the following categories: health, mental health, quit an habit, money, job, meet new romantic partner, meet new business partner, sell a property, come up with a business idea, go viral. 
    //                then output a positive response or remark made by someone about how lucky the user i.e ${type} is that they are experiencing the ${itisdone}. The remark is in a tone as if ${itisdone} has already been acheived

    //                 Tone: The conversation must be positive and exciting.
    //                 setting:The speaker is either talking to the user or someone else but the user never speaks. 
    //                 Also, If timeline is mentioned in "${itisdone}", avoid mentioning the timeline.

    //                 Example Output:
    //                 Category: Health
    //                 Example: "${type}, I'm calling to say from all the tests we did we still can't find a trace of ${itisdone}."

    //                 Situation: Mental Health
    //                 Example 1: "${type}, you've never experienced ${itisdone} for over 6 years now? Wow, that's awesome!"
    //                 Example 2: "Can I ask you a question, ${type}? Why are you always so ${itisdone}?"

    //                 Situation: Quit an Habit
    //                 Example: "That's awesome, ${type}, I wish I was as strong as you. I can imagine you've not ${itisdone} for over 5 years now. Congrats!"

    //                 Situation: Money
    //                 Example: "I mistakenly saw the balance in ${type}'s account, can't believe my eyes. Do you know how much I saw? $16804000! Did not know ${type} was that rich. I've never seen that much in an account."

    //                 Situation: Job
    //                 Example: "Congrats, ${type}, heard you got your dream job!"

    //                 Situation: Meet New Romantic Partner
    //                 Example 1: "${type}, your eyes light up whenever you talk about your relationship. I'm so jealous of you two."
    //                 Example 2: "Can't believe you found 'the one', ${type}, you're one of the lucky few."

    //                 Situation: Meet New Business Partner
    //                 Example: "${type} Where did you meet your co-founder? You guys are so in sync."

    //                 Situation: Sell a Property
    //                 Example: "Hey! Congrats, ${type}, your ${itisdone} has been sold and the funds transferred to your account."

    //                 Situation: Come Up with a Business Idea
    //                 Example: "It's so intuitive and useful. I wish I thought of that. Can't believe you came up with that idea, ${type}. Why didn't I think of that?"

    //                 Situation: Go Viral
    //                 Example: "Wow, ${type}, everyone is talking about ${itisdone} online. You're literally everywhere! Well done!"
    //             `

    //           }
    //         ],
    //         "temperature": 0.7,
    //         "max_tokens": 150,
    //         "top_p": 1,
    //         "frequency_penalty": 0,
    //         "presence_penalty": 0
    //       },
    //       {
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'Authorization': 'Bearer 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', // Replace with your OpenAI API key
    //         },
    //       }
    //     );
    //     const conversationText = response.data.choices[0].message.content;
    //     console.log("conversation: ", conversationText)
    //     // Find the index of the first occurrence of "Example: "
    //     const exampleIndex = conversationText.indexOf(' "');

    //     // Extract the example substring starting from "Example: " to the end of the conversation
    //     const example = conversationText.substring(exampleIndex + ' "'.length).trim();
    //     console.log("new conversation: ", example);
    //     convertTextToAudio(example)
    //     // setConversation2(example);
    //     // handleConversationTransfer(example)
    //     // handleTypeTransfer(prayerType)
    //     // convertTextToAudio(conversationText);
    //   } catch (error) {
    //     console.error('Error fetching conversation:', error.message);
    //   }
    // };

  
  const convertTextToAudio = async (text) => {
    try {

        setIsLoading(true); // Start loading spinner
        console.log('audio conversation2: ', conversation)

        // / Generate a random index to select a voice from the voices array
        const randomIndex = Math.floor(Math.random() * voices.length);
        const selectedVoice = voices[randomIndex];
        const response = await axios.post(
        'https://api.openai.com/v1/audio/speech',
        {
          "model": "tts-1",
          "input": text,
          "voice": selectedVoice
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 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', // Replace with your OpenAI API key
          },
          responseType: 'blob' // Specify response type as blob
        }
        // { responseType: 'blob' }

      );
      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' }); // Create a blob from the response data
      const audioUrl = URL.createObjectURL(audioBlob); // Create object URL from the blob
      console.log("audioUrl: ", audioUrl)
    // console.log("audioUrl Type: ", audioUrl.fileType())
      //   playAudio(audioUrl); // Play the audio
      // setAudioUrl(audioUrl)
      setAudioUrls(prevUrls => [...prevUrls, audioUrl]);
    } catch (error) {
      console.error('Error converting text to audio:', error.message);
    } finally {
            setIsLoading(false); // Stop loading spinner
    }

  };

  const handleCreateButtonClick = () => {
    // convertTextToAudio(itisdone);
    // fetchConversation()
    setZoomModalShow(false); // Close the modal after creating audio
    setItisdone(''); // Reset input field
};

//   useEffect(() => {
//     if (audioUrl) {
//         joinAndImposeAudio(audioUrl, 'path/to/city.mp3', 'output.mp3');
//     }
// }, [audioUrl]);

// const joinAndImposeAudio = (audioFile1, audioFile2, outputFileName) => {
//     const command = `ffmpeg -i ${audioFile1} -i ${audioFile2} -filter_complex "[0:a][1:a]amix=inputs=2:duration=first[aout]" -map "[aout]" ${outputFileName}`;

//     exec(command, (error, stdout, stderr) => {
//         if (error) {
//             console.error(`Error: ${error.message}`);
//             return;
//         }
//         if (stderr) {
//             console.error(`stderr: ${stderr}`);
//             return;
//         }
//         console.log(`Audio files joined and imposed successfully. Output file: ${outputFileName}`);
//         setAudioUrl(`path/to/${outputFileName}`);
//     });
// };

  const handlePlayClick = () => {
    if (audioUrl) {
      const audio = new Audio(audioUrl);
      audio.play();
    }
  };

  // Function to handle audio replay
  const handleAudioReplay = () => {
    setReplayCount(prevCount => prevCount + 1);
    if (replayCount < 5) {
        audioRef.current.play();
    }
};

// Reset replay count when conversation changes
useEffect(() => {
    setReplayCount(0);
}, [conversation]);

useEffect(() => {
  if (audioUrl && !audioRef.current.paused) {
      startTopUp();
  } else {
      stopTopUp();
  }
}, [audioUrl]);

const renderConversationWithOptions = (text) => {
  const sentences = text.split('.').filter(sentence => sentence.trim().length > 0);
  return sentences.map((sentence, index) => (
      <div key={index} style={{ marginBottom: 10 }}>
          <p>{sentence}.</p>
          <Form.Select style={{ marginTop: 10 }}>
              {options.map(option => (
                  <option key={option} value={option}>{option}</option>
              ))}
          </Form.Select>
      </div>
  ));
};

  return (
    <div>
      {/* <Breadcrumb> */}
        {/* <Breadcrumb.Item onClick={pauseAudio}>Hints ?</Breadcrumb.Item> */}
        {/* <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
          Library
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Data</Breadcrumb.Item> */}
      {/* </Breadcrumb> */}
      {/* <h2 style={{marginBottom:35}}>Just Be it</h2> */}
      <h1 style={{color: '#797979', fontSize: '25px', fontWeight: 900, marginBottom:20, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh'}}>Bring your thoughts to the Light</h1>
       {/* Countdown timer displayed */}
       <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                {renderConversationWithOptions(conversation)}
        </div>
      
        {isLoading && (
          <Spinner style={{ justifyContent: 'center', alignItems: 'center'}} animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {/* {audioUrl && !isLoading && (
          <audio 
          style={{  borderRadius:30, 
            marginTop: 5,
            // height: 25, 
            width: '100%',
            // appearance: 'none',
            marginBottom:5,
            // background: `linear-gradient(to right, red 0%, red ${progress * 20}%, #CED4DA ${progress * 20}%, #CED4DA 100%)`
            }}
          ref={audioRef} controls autoPlay={false} loop={false} onEnded={handleAudioReplay}>
              <source src={audioUrl} type="audio/mpeg" />
              Your browser does not support the audio element.
          </audio>
        )} */}
        

        <>
          {/* <Form.Label>How much do you believe this will manifest ?</Form.Label> */}
          {/* <input
            style={{  borderRadius:30, 
            marginTop: 5,
            height: 25, 
            width: '100%',
            appearance: 'none',
            marginBottom:5,
            background: `linear-gradient(to right, black 0%, black ${progress}%, #CED4DA ${progress}%, #CED4DA 100%)`,
            }}
              type="range"
              min="0"
              max="100"
              value={progress}
              onChange={(e) => setProgress(parseInt(e.target.value))}
          /> */}
        </>

        

        {/* <Button onClick={handlePlayClick}>Play</Button> */}
        {/* <br /> */}

        {/* <Stack direction="horizontal" gap={2}> */}
          {/* <div className="p-2"> */}
            {/* <Button onClick={pauseAudio} style={{ marginTop:35, fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, borderRadius: 50, height: 60, width: '100%', backgroundColor: '#000000'}} variant="dark"> Hints </Button> */}
          {/* </div> */}
          {/* <div className="p-2"> */}
            {/* <Link to="/"> */}
              {/* <Button style={{ marginTop:35, fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, borderRadius: 50, height: 60, width: '100%', backgroundColor: '#000000'}} variant="dark">It Is So !</Button> */}
            {/* </Link> */}
          {/* </div> */}
        {/* </Stack> */}

        {/* <Button onClick={setProgress(parseInt())} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:35, fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, borderRadius: 10, height: 60, width: '30%', backgroundColor: '#000000'}} variant="dark">Top It Up</Button> */}
        {/* <Button 
                onMouseDown={startTopUp} 
                onMouseUp={stopTopUp} 
                onMouseLeave={stopTopUp} 
                onTouchStart={startTopUp} 
                onTouchEnd={stopTopUp}
                style={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    marginTop: 35, 
                    fontFamily: 'Lexend Deca', 
                    fontSize: '20px', 
                    fontWeight: 80, 
                    borderRadius: 10, 
                    height: 60, 
                    width: '30%', 
                    backgroundColor: '#000000'
                }} 
                variant="dark"
            >
                Top It Up
            </Button> */}
            {audioUrls.map((url, index) => (
                <div key={index} className={`audio-player audio-player-${index}`}>
                    <audio controls autoPlay={false} loop={false} onEnded={handleAudioReplay}>
                        <source src={url} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                </div>
            ))}
            <Button 
                onClick={() => setZoomModalShow(true)}
                style={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    marginTop: 35, 
                    fontFamily: 'Lexend Deca', 
                    fontSize: '20px', 
                    fontWeight: 80, 
                    borderRadius: 10, 
                    height: 60, 
                    width: '30%', 
                    backgroundColor: '#000000'
                }} 
                variant="dark"
            >
                Zoom
            </Button>


        <Modal show={modalShow} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {prayerType}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       
              <video  style={{width:"100%"}} controls>
                <source src={"https://www.youtube.com/watch?v=XGJcJlyEdO8"} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
        
          <Form.Group className="mb-3">
          <div style={{ marginBottom:15, fontFamily: 'Lexend Deca', fontSize: '15px', fontWeight: 80, }}>
            {body}
          </div>
           
            <Form.Check 
              required
              label="Do you accept your body/brain creates every feeling you feel?"
              feedback="You must accept to move forward."
              feedbackType="invalid"
            />
            <Form.Check style={{ marginTop:10}}
                required
                label="Do you accept, you can give yourself the permission to feel blessings even before it physicalize? "
                feedback="You must accept to move forward."
                feedbackType="invalid"
              />
            <Form.Check style={{ marginTop:10}}
              required
              label="Do you give YOURSELF the permission to feel the feeling of success NOW 
              even before it arrives? "
              feedback="You must accept to move forward."
              feedbackType="invalid"
            /> 
          </Form.Group>
        </Modal.Body>

        <Button onClick={() => setModalShow(false)} style={{ marginTop:15, marginBottom:25, fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, borderRadius: 50, height: 60, width: '100%', backgroundColor: '#000000'}} variant="dark">I accept</Button>

       
      </Modal>


      <Modal show={zoomModalShow} onHide={closeZoomModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
          <Form>
            <FloatingLabel
              controlId="floatingTextarea"
              label="Why do you think it can't happen?"
              md="4"
              // fontColor= '#797979'
            >
            <Form.Control style={{ marginTop:4, marginBottom:16, textTransform: "capitalize", borderRadius: 5}} as="textarea" rows={3} placeholder="What are you manifesting?" value={itisdone} onChange={(e) => setItisdone(e.target.value)} />
            </FloatingLabel>
            

            <Form.Group className="mb-3">

      
            </Form.Group>
          </Form>
        </div>
        </Modal.Body>

        <Button onClick={'handleCreateButtonClick'} style={{ marginTop:15, marginBottom:25, fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, borderRadius: 50, height: 60, width: '100%', backgroundColor: '#000000'}} variant="dark"> Create </Button>

       
      </Modal>

      {/* CSS styles */}
      <style jsx>{`
                .audio-player {
                    margin-bottom: 20px;
                }
                .audio-player audio {
                    width: 100%;
                }
                .audio-player-0 audio {
                    background-color: #FF5733; /* Example color */
                }
                .audio-player-1 audio {
                    background-color: #33FF57; /* Example color */
                }
                /* Add more styling rules for additional audio players */
            `}</style>

    </div>
  );
}

export default AudioPlayer;
